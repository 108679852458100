@import "../../../variables";

.hamburger-content {
  background: #fff;
}

.hamburger-nav {
  position: absolute;
  margin-top: 34px;
  width: 100%;
  margin-right: 30px;

  button {
    display: inline-block;
    text-indent: -999em;
    width: 26px;
    height: 26px;
    border: none;
    margin: 0 26px;
    padding: 0;
    position: absolute;

    &.hamburger-icon {
      background: transparent url(../../images/hb-nav-menu.svg) no-repeat scroll 0 0;
    }
    &.hamburger-icon.hm-open {
      background: transparent url(../../images/hb-nav-close.svg) no-repeat scroll center center;
    }

    &:focus-visible {
      outline-offset: 4px !important   ;
    }
  }
  .hb-nav-content {
    background: #ffffff;
    margin-top: 57px;
    display: inline-block;
    padding: 0 0 35px 55px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    z-index: 2;
    max-width: 1140px;

    .content-col {
      display: inline-block;
      float: left;
      max-width: 270px;
      width: 21%;
      padding: 0;
      margin-right: 30px;
    }
    .hm-close {
      display: none;
    }
    .hm-open {
      display: block;
      padding-top: 20px;
      font-size: 24px;
      font-weight: 700;
      color: #000;
      line-height: 38px;
    }
  }
  .hb-df-titles {
    font-size: 18px;
    font-weight: 300;
    border-bottom: 1px solid #dbdbdb;
    padding: 22px 0 10px 0;
    display: block;
    text-decoration: none;
    color: $text-primary;

    @include custom-link;
  }
  ul {
    padding: 10px 0;
    li {
      list-style: none;
      a {
        color: $text-primary;
        font-size: 14px;
        font-weight: 400;
        padding: 8px 0;
        display: inline-block;
        text-decoration: none;
        @include custom-link;
      }
    }
  }
}

// pdp page hamburger view
.pdp-hamburger {
  .hamburger-nav-pdp {
    position: fixed;
    top: 34px;
    z-index: 3;
    width: 48px;

    button {
      display: inline-block;
      text-indent: -999em;
      width: 26px;
      height: 26px;
      border: none;
      margin: 0 26px;
      padding: 0;

      &.hamburger-icon {
        background: #fff url(../../images/hb-nav-menu.svg) no-repeat scroll 0 0;
      }
      &.hamburger-icon.hm-open {
        background: #fff url(../../images/hb-nav-close.svg) no-repeat scroll center center;
      }
    }
  }
  .hb-nav-content {
    background: #ffffff;
    margin-top: 90px;
    display: inline-block;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    z-index: 1;

    .content-col {
      display: inline-block;
      float: left;
      max-width: 270px;
      width: 21%;
      padding: 0;
      margin-right: 30px;

      ul {
        padding: 10px 0;
        li {
          list-style: none;
          a {
            color: $text-primary;
            font-size: 14px;
            font-weight: 400;
            padding: 8px 0;
            display: inline-block;
            text-decoration: none;
            @include custom-link;
          }
        }
      }
    }
    .hm-close {
      display: none;
    }
    .hm-open {
      display: block;
      padding-top: 20px;
      font-size: 24px;
      font-weight: 700;
      color: #000;
      line-height: 38px;
      width: 100%;
    }
  }
  .hb-df-titles {
    font-size: 18px;
    font-weight: 300;
    border-bottom: 1px solid #dbdbdb;
    padding: 22px 0 10px 0;
    display: block;
    text-decoration: none;
    color: $text-primary;
    @include custom-link;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .hamburger-nav {
    button {
      margin: 0 20px;
    }
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  button {
    border: 1px solid #fff;
    &.hamburger-icon &.hamburger-icon.hm-open {
      fill: #fff;
    }
  }

  .hamburger-nav,
  .hamburger-nav-pdp {
    button {
      &.hamburger-icon {
        background: transparent url(../../images/hc-hb-nav-menu.svg) no-repeat scroll 0 0 !important;
      }
      &.hamburger-icon.hm-open {
        background: transparent url(../../images/hc-hb-nav-close.svg) no-repeat scroll center center !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .hb-nav-content {
    padding: 0 0 35px 55px !important;
  }
}
@media (max-width: 992px) {
  .hb-nav-content {
    padding-right: 55px !important;
    padding-left: 55px !important;
    box-sizing: border-box;
    .content-col {
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  .hamburger-nav button,
  .pdp-hamburger .hamburger-nav-pdp button {
    margin: 0 0 0 15px;
  }
}
@media (max-width: 600px) {
  .hb-nav-content {
    padding: 0 25px 35px 25px !important;
  }
}
