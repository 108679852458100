@import "../../../variables";

.offer-opt-info {
    &:not(:last-child) {
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 0;
    }
    &.no-offer {
        p {
            padding-bottom: 0;
        }
    }

    .offer-opt-info-title {
        @include product-info(16px, 700, 26px);
        padding-bottom: 5px;
        color: $text-primary;
        padding-top: 10px;
    }
    .offer-info {
        @include product-info(16px, 400, 26px);
        color: $text-color-secondary;
        padding-bottom: 5px;
        &.isbn {
            padding-bottom: 0;
        }
        &.coming-soon-offer-txt {
            margin-top: 15px;
            padding-bottom: 0;
        }
        &:not(:last-child) {
            padding-bottom: 5px;
        }
        &:nth-last-child() {
            padding-bottom: 0;
        }
    }
    .oops-alert-icon {
        background: url(../../images/no-offer-alert-icon.svg) no-repeat 0 0;
        display: inline-block;
        height: 24px;
        width: 24px;
        margin: 15px 0;
    }
    .offer-currency-txt {
        @include product-info(16px, 700, 26px);
        padding-top: 10px;
        padding-bottom: 10px;
        color: $text-color-secondary;
    }
}
.offer-opt-info:nth-last-child() {
    margin-bottom: 0;
}
.offer-tabs {
    &.nav-tabs {
        flex-direction: row;
        flex-wrap: nowrap !important;
        border: 1px solid $pill-bg;
        border-right: transparent;
        border-left: transparent;
        margin-top: 30px;

        .nav-link {
            @include product-info(14px, 400, 21px);
            color: $text-color-secondary;
            text-decoration: none;
            margin-bottom: 0;
            padding: 13px 0;
            @include custom-link;
            border: none;
            box-shadow: none;
            width: 50%;
            text-align: center;

            &:hover {
                text-decoration: none;
            }

            &.active {
                color: $text-primary !important;
                font-weight: 600;
                border-bottom: 2px solid $text-primary !important;
                background: #f5f5f5;
            }
        }
    }
}
.coming-soon-text-k12 {
    @include product-info(14px, 400, 21px);
    color: $text-color-secondary;
}
