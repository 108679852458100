body #onetrust-consent-sdk #onetrust-banner-sdk {
    width: 100% !important;
    max-width: 100% !important;
    position: fixed;
    bottom: 0 !important;
    z-index: 2147483645;
    left: 0px !important;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk div.ot-sdk-container {
    background-color: #fff;
    width: 100%;
  }
  @media (min-width: 480px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk div.ot-sdk-container {
      padding: 40px 64px;
    }
  }
  
  @media (min-width: 360px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk div.ot-sdk-container {
      padding: 40px 64px;
    }
  }
  
  body #onetrust-consent-sdk #onetrust-banner-sdk:focus {
    outline: 0;
  }
  body #onetrust-consent-sdk #onetrust-pc-sdk.otPcCenter {
    border-radius: 8px;
  }
  body #onetrust-consent-sdk #onetrust-policy-title {
    font-weight: 600;
    font-size: 18px !important;
    line-height: 1.5 !important;
    color: #252525 !important;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row {
    display: flex;
    width: 100%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-group-container,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-group-container {
    width: auto;
    padding-right: 30px;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-group-container #onetrust-policy,
  body
    #onetrust-consent-sdk
    #onetrust-banner-sdk.ot-buttons-fw
    .ot-sdk-container
    .ot-sdk-row
    #onetrust-group-container
    #onetrust-policy {
    margin: 0;
    overflow: visible;
  }
  body
    #onetrust-consent-sdk
    #onetrust-banner-sdk
    .ot-sdk-container
    .ot-sdk-row
    #onetrust-group-container
    #onetrust-policy
    #onetrust-policy-text,
  body
    #onetrust-consent-sdk
    #onetrust-banner-sdk.ot-buttons-fw
    .ot-sdk-container
    .ot-sdk-row
    #onetrust-group-container
    #onetrust-policy
    #onetrust-policy-text {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    line-height: 26px;
    color: #252525;
    text-align: left;
    margin: 0;
  }
  body
    #onetrust-consent-sdk
    #onetrust-banner-sdk
    .ot-sdk-container
    .ot-sdk-row
    #onetrust-group-container
    #onetrust-policy
    #onetrust-policy-text
    a,
  body
    #onetrust-consent-sdk
    #onetrust-banner-sdk.ot-buttons-fw
    .ot-sdk-container
    .ot-sdk-row
    #onetrust-group-container
    #onetrust-policy
    #onetrust-policy-text
    a {
    color: #047a9c;
    font-weight: inherit;
    text-decoration: underline;
    margin-left: 5px;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-close-btn-container-mobile,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-close-btn-container-mobile {
    display: none;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-button-group,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-button-group {
    width: auto;
    margin-right: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  
  body
    #onetrust-consent-sdk
    #onetrust-banner-sdk
    .ot-sdk-container
    .ot-sdk-row
    #onetrust-button-group
    button#onetrust-accept-btn-handler {
    margin-right: 20px !important;
  }
  
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 4px;
    border: 2px solid;
    transition: all 0.135s ease;
    margin: 0;
    border-radius: 30px;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler:hover,
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler:hover,
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler:hover,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler:hover,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler:hover,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler:hover {
    opacity: 1;
  }
  @media (max-width: 480px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler {
      padding: 15px 20px;
      margin-top: 20px !important;
    }
  }
  @media (min-width: 376px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler {
      padding: 12px 20px;
    }
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler {
    color: #252525;
    background-color: #e9e9e9;
    border-color: #e9e9e9;
    margin-right: 20px;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler:focus,
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler:focus,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler:focus,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler:focus {
    background-color: #047a9c;
    color: #fff;
    border: 2px solid #047a9c;
    opacity: 1;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler:active,
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler:active,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler:active,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler:active {
    background-color: #047a9c;
    color: #fff;
    opacity: 1;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler:hover,
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler:hover,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler:hover,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler:hover {
    color: #252525;
    background-color: #fff;
    opacity: 1;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler {
    color: #fff;
    background-color: #047a9c;
    border-color: #047a9c;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler:focus,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler:focus {
    opacity: 1;
    color: #fff;
    background-color: #047a9c;
    border: 2px solid #047a9c;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler:active,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler:active {
    background-color: #cfdaf0;
    color: #252525;
    opacity: 1;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler:hover,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler:hover {
    opacity: 1;
    color: #252525;
    background-color: #fff;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-close-btn-container,
  body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-close-btn-container {
    display: none;
  }
  body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row div#onetrust-button-group-parent.ot-sdk-columns,
  body
    #onetrust-consent-sdk
    #onetrust-banner-sdk.ot-buttons-fw
    .ot-sdk-container
    .ot-sdk-row
    div#onetrust-button-group-parent.ot-sdk-columns {
    display: flex;
    width: auto;
    flex-grow: 1;
    position: relative;
    left: 0;
    margin: 0;
    padding: 0;
    -webkit-transform: none;
    transform: none;
  }
  @media (min-width: 1035px) {
    body
      #onetrust-consent-sdk
      #onetrust-banner-sdk
      .ot-sdk-container
      .ot-sdk-row
      div#onetrust-button-group-parent.ot-sdk-columns
      #onetrust-button-group,
    body
      #onetrust-consent-sdk
      #onetrust-banner-sdk.ot-buttons-fw
      .ot-sdk-container
      .ot-sdk-row
      div#onetrust-button-group-parent.ot-sdk-columns
      #onetrust-button-group {
      margin-top: 0;
    }
  }
  @media (min-width: 992px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row div#onetrust-button-group-parent.ot-sdk-columns,
    body
      #onetrust-consent-sdk
      #onetrust-banner-sdk.ot-buttons-fw
      .ot-sdk-container
      .ot-sdk-row
      div#onetrust-button-group-parent.ot-sdk-columns {
      flex-shrink: 0;
      margin-top: 40px;
    }
  }
  @media (max-width: 991px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row {
      display: block;
    }
  }
  
  @media only screen and (min-width: 768px) {
    #onetrust-banner-sdk #onetrust-policy-text,
    #onetrust-banner-sdk :not(.ot-dpd-desc) > .ot-b-addl-desc,
    #onetrust-banner-sdk .ot-dpd-container {
      padding: 0px !important;
      margin-bottom: 20px !important;
    }
  }
  @media (max-width: 767px) {
    body #onetrust-banner-sdk #onetrust-policy-text,
    #onetrust-banner-sdk .ot-b-addl-desc {
      padding: 0px !important;
    }
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-group-container,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-group-container {
      max-width: unset;
      padding-right: 0;
      width: 100%;
    }
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-button-group,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-button-group {
      display: block;
      width: 100%;
      margin-left: 0;
    }
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler {
      display: block;
      margin-top: 0px;
      width: 100%;
    }
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler {
      margin-left: 0;
    }
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row div#onetrust-button-group-parent.ot-sdk-columns,
    body
      #onetrust-consent-sdk
      #onetrust-banner-sdk.ot-buttons-fw
      .ot-sdk-container
      .ot-sdk-row
      div#onetrust-button-group-parent.ot-sdk-columns {
      display: block;
      width: 100%;
    }
  }
  @media (max-width: 991px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-group-container,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-group-container {
      max-width: unset;
      padding-right: 0;
    }
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler {
      margin-right: 20px;
    }
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler,
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler {
      margin-left: 0;
      margin-top: 0px;
    }
  }
  @media (min-width: 1280px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row div#onetrust-button-group-parent.ot-sdk-columns,
    body
      #onetrust-consent-sdk
      #onetrust-banner-sdk.ot-buttons-fw
      .ot-sdk-container
      .ot-sdk-row
      div#onetrust-button-group-parent.ot-sdk-columns {
      position: relative;
      left: 0;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk {
    font-family: Open Sans, sans-serif;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-vlst-cntr > a:focus,
  body #onetrust-consent-sdk div#onetrust-pc-sdk :focus {
    outline: 2px solid #047a9c;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-chkbox {
    z-index: 2;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input[type='checkbox']:not(div.ot-tgl > input[type='checkbox']) {
    z-index: -1;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input[type='checkbox']:not(div.ot-tgl > input[type='checkbox']):before {
    content: '';
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input[type='checkbox']:not(div.ot-tgl > input[type='checkbox']):hover {
    background-color: transparent;
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
    outline: 0;
    width: 0;
    height: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input[type='checkbox']:not(div.ot-tgl > input[type='checkbox']):hover:before {
    content: '';
    display: block;
    width: 2.25em;
    height: 2.25em;
    border-radius: 50%;
    background-color: #fff;
    flex: none;
    margin: auto;
    position: absolute;
    left: -9px;
    top: -9px;
    z-index: -1;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    transition: all 0.25s ease-in-out;
    outline-width: 0.1875em;
    outline-style: solid;
    outline-color: transparent;
    outline-offset: -0.1875em;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input[type='checkbox']:not(div.ot-tgl > input[type='checkbox']):focus {
    background-color: transparent;
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
    outline: 0;
    width: 0;
    height: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input[type='checkbox']:not(div.ot-tgl > input[type='checkbox']):focus:before {
    content: '';
    display: block;
    width: 2.25em;
    height: 2.25em;
    border-radius: 50%;
    background-color: transparent;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    outline-color: #047a9c;
    flex: none;
    margin: auto;
    position: absolute;
    left: -9px;
    top: -9px;
    z-index: -1;
    outline-width: 0.1875em;
    outline-style: solid;
    outline-offset: -0.1875em;
    border: unset;
    transition: all 0.15s ease-in-out;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input#select-all-hosts-groups-handler:before {
    top: -7px;
    left: -7px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input#select-all-hosts-groups-handler:focus:before,
  body #onetrust-consent-sdk div#onetrust-pc-sdk input#select-all-hosts-groups-handler:hover:before {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    outline-width: 0.22em;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl input:focus + .ot-switch {
    border: 1px solid #047a9c;
    border-radius: 20px;
    box-shadow: 0 0 0 2px #f5f5f5;
    outline: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header {
    width: 100%;
    padding: 0;
    height: 0;
    box-sizing: border-box;
    border: 0;
    position: relative;
    z-index: 3;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-pc-logo {
    width: 100%;
    height: 34px;
    display: inline-block;
    height: 64px;
    background: #fff;
    border-bottom: 1px solid #047a9c;
    background-position: 16px 16px;
    background-repeat: no-repeat;
    background-size: 128px 28px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 65px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-pc-logo img {
    width: 116px;
    margin-top: 15px;
    margin-left: 30px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header #close-pc-btn-handler {
    top: 7px;
    z-index: 4;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-close-cntr {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-btn-container button {
    font-size: 16px;
    line-height: 21px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr {
    margin: 0 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #047a9c;
  }
  @media (max-width: 600px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-pc-hdr {
      margin: 0;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-cnt {
    width: calc(100% - 40px);
    max-height: calc(100% - 150px);
  }
  @media (max-width: 480px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-cnt {
      max-height: calc(100% - 210px);
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt {
    max-height: none;
    height: auto;
    padding: 0 30px 30px;
    width: auto;
    top: 115px;
    right: 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt #clear-filters-handler {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #252525;
    text-align: end;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt #clear-filters-handler[disabled='true'] {
    color: #252525;
  }
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    #ot-pc-lst
    #ot-fltr-cnt
    #clear-filters-handler:has(+ .ot-fltr-scrlcnt input[type='checkbox']:checked) {
    color: #252525;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-label-txt {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #333;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-chkbox label {
    display: flex;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-fltr-opt {
    width: auto;
    margin: 0 0 10px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-fltr-btns {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 10px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-fltr-btns #filter-cancel-handler {
    padding: 6px 22px;
    background: #fff;
    border: 2px solid #047a9c;
    border-radius: 4px;
    color: #252525;
    margin: 0;
    width: 100%;
    border-radius: 18px;
    height: 36px;
    font-size: 1.6rem;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-fltr-btns #filter-cancel-handler:focus,
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-fltr-btns #filter-cancel-handler:hover {
    background: #fff;
    color: #252525;
    opacity: 1;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-fltr-btns #filter-apply-handler {
    background: #fff;
    border: 2px solid #047a9c;
    border-radius: 4px;
    padding: 6px 22px;
    margin: 0;
    width: 100%;
    border-radius: 18px;
    color: #252525;
    height: 36px;
    font-size: 1.6rem;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-fltr-btns #filter-apply-handler:focus,
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-fltr-cnt .ot-fltr-btns #filter-apply-handler:hover {
    background: #fff;
    color: #252525;
    opacity: 1;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-title {
    margin: 25px 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-title h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #252525;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr {
    display: flex;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-search-cntr {
    width: 100%;
    flex: 5 1;
  }
  @media (max-width: 480px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-search-cntr {
      flex: 3 1;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr .ot-fltr-cntr {
    display: flex;
    width: 100%;
    flex: 1 1;
    justify-content: flex-end;
    right: -5px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-lst-subhdr #ot-fltr-modal {
    flex: 0 1;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr {
    width: 73%;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr #vendor-search-handler {
    padding: 10px 15px;
    background: #fff;
    border: 1px solid #047a9c;
    border-radius: 4px;
    height: 48px;
    margin-left: 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: 600px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr #vendor-search-handler {
      margin-left: 40px;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr > svg {
    top: 9px;
    right: -30px;
  }
  @media (max-width: 600px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr > svg {
      right: -40px;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr input::-webkit-input-placeholder {
    color: #252525;
    font-style: normal;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr input:-ms-input-placeholder {
    color: #252525;
    font-style: normal;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-search-cntr input::placeholder {
    color: #252525;
    font-style: normal;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-fltr-cntr button {
    position: relative;
    background: transparent;
    border: 0;
    margin-right: 10px;
    margin-top: 8px;
    border-radius: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-fltr-cntr button svg {
    display: none;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-fltr-cntr button:before {
    content: '';
    width: 22px;
    height: 17px;
    background: transparent
      url(https://www.pearson.com/etc/clientlibs/one-dot-com/one-dot-com/pearsonplus-navigation/assets/icons/icon-filters.svg) 50%
      no-repeat;
    background-size: 22px 17px;
    position: absolute;
    left: calc(100% - 27px);
    top: calc(50% - 7.5px);
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-fltr-cntr button:focus {
    outline: 0;
    background: #047a9c;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-fltr-cntr button:hover {
    background: #047a9c;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-anchor {
    display: none !important;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-consent-hdr {
    max-width: none;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-sel-blk {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid #047a9c;
    padding: 15px 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-sel-blk .ot-sel-all {
    display: flex;
    padding: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-sel-blk .ot-sel-all .ot-consent-hdr {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #252525;
    margin-right: 15px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-sel-blk .ot-sel-all .ot-sel-all-hdr {
    width: 100%;
    margin-right: 22px;
    font-size: 14px;
    line-height: 21px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-sel-blk .ot-sel-all .ot-sel-all-chkbox {
    width: 20px;
    margin-right: 22px;
    font-size: 14px;
    line-height: 21px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox label:before {
    border: 1px solid #878d99;
    outline: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input:checked ~ label:before {
    background-color: #047a9c;
    outline: 0;
    border: 1px solid #047a9c;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[disabled='true']:checked ~ label:before {
    background-color: #878d99;
    outline: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-chkbox input[disabled='true'] ~ label:before {
    border: 1px solid #878d99;
    outline: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-host-hdr h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #252525;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-host-hdr h3 a {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #047a9c;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-host-hdr .ot-host-expand {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #047a9c;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-acc-txt {
    width: 100%;
    margin: 20px 0 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-acc-txt .ot-host-info {
    background: #f7f9fd;
    padding: 20px;
    margin-bottom: 2px;
    box-sizing: border-box;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-acc-txt .ot-host-info a {
    color: #047a9c;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-acc-txt .ot-host-info > div div {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer {
    position: absolute !important;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-content {
    top: 0;
    margin: 0;
    padding: 0;
    width: auto;
    bottom: 100px;
    height: 100%;
    max-height: calc(100% - 95px);
  }
  @media (max-width: 480px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-content {
      max-height: calc(100% - 175px);
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-content:before {
    display: block;
    content: '';
    height: 64px;
    background: #fff;
    border-bottom: 1px solid #047a9c;
    background-position: 16px 16px;
    background-repeat: no-repeat;
    background-size: 128px 28px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 65px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.1;
    color: #252525;
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
    float: none;
    background: #fff;
    z-index: 3;
  }
  @media (max-width: 600px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-title {
      padding: 25px 25px 15px;
    }
  }
  @media (min-width: 601px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-title {
      padding: 30px 32px 15px;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-desc {
    width: auto;
    font-size: 14px;
    line-height: 1.5;
    color: #252525;
  }
  @media (max-width: 600px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-desc {
      padding: 0 22px 0 24px;
    }
  }
  @media (min-width: 601px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-desc {
      padding: 0 22px 0 32px;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .privacy-notice-link {
    margin-top: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.5;
    color: #047a9c;
    display: inline-block;
    text-decoration: none;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-desc + #accept-recommended-btn-handler {
    background-color: #047a9c !important;
    border-color: #047a9c !important;
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    padding: 12px 0;
    margin-bottom: 0;
    border: 2px solid #252525;
    color: #fff !important;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-desc + #accept-recommended-btn-handler:focus,
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-desc + #accept-recommended-btn-handler:hover {
    background-color: #047a9c !important;
    color: #fff !important;
    border: 2px solid #047a9c;
    opacity: 1;
  }
  @media (max-width: 600px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-desc + #accept-recommended-btn-handler {
      margin-left: 24px;
      margin-right: 24px;
      width: calc(100% - 48px);
      max-width: 100%;
    }
  }
  @media (min-width: 601px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-desc + #accept-recommended-btn-handler {
      margin-left: 32px;
      min-width: 150px;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-row.ot-cat-grp {
    margin-top: 0;
    width: auto;
  }
  @media (max-width: 600px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-row.ot-cat-grp {
      padding: 0 22px 0 24px;
    }
  }
  @media (min-width: 601px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-row.ot-cat-grp {
      padding: 0 22px 0 32px;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk #ot-category-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    color: #252525;
    margin-top: 44px;
    margin-bottom: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-plus-minus {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-cat-item > button {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #fff;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container {
    width: auto !important;
    margin-left: 0;
  }
  @media (max-width: 480px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container {
      padding: 24px !important;
    }
  }
  @media (min-width: 481px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container {
      padding: 20px 33px !important;
    }
  }
  @media (max-width: 480px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container > button {
      width: 100% !important;
    }
  }
  @media (min-width: 481px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container > button {
      width: calc(50% - 12px) !important;
    }
  }
  @media (max-width: 480px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container > button:first-child {
      margin-top: 0 !important;
    }
    body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container > button:last-child {
      margin-top: 20px !important;
    }
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-accordion-layout .ot-cat-header {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer-logo {
    display: none !important;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-switch {
    width: 64px;
    height: 32px;
    margin-top: 10px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-switch-nob {
    background-color: #878d99;
    border-color: transparent;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-switch-nob:before {
    background-color: #fefefe;
    width: 24px;
    height: 24px;
    bottom: 3px;
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-switch-nob:after {
    content: '';
    width: 15px;
    height: 15px;
    background: transparent
      url(https://www.pearson.com/etc/clientlibs/one-dot-com/one-dot-com/pearsonplus-navigation/assets/icons/icon-unchecked.svg) 50%
      no-repeat;
    background-size: 15px 15px;
    position: absolute;
    top: 8px;
    left: 35px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-switch-nob[aria-checked='true']:after {
    background: transparent
      url(https://www.pearson.com/etc/clientlibs/one-dot-com/one-dot-com/pearsonplus-navigation/assets/icons/icon-checked.svg) 50%
      no-repeat;
    left: 10px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input:checked + .ot-switch .ot-switch-nob {
    background-color: #047a9c;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk input:checked + .ot-switch .ot-switch-nob:before {
    -webkit-transform: translateX(35px);
    transform: translateX(35px);
    border-color: transparent;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-cat-grp .ot-always-active {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #252525;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-acc-grpdesc {
    padding: 0;
    margin: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-acc-grpcntr.ot-acc-txt {
    padding: 24px;
    background-color: #fff;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-hlst-cntr {
    margin: 16px 0 0;
    padding: 0;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .category-host-list-handler {
    color: #252525;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-link-btn {
    color: #047a9c;
    font-weight: 700;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-cat-grp .ot-abt-tab .category-menu-switch-handler,
  body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-cat-grp .ot-cat-item .category-menu-switch-handler {
    border-left-width: 3px;
    padding-left: 15px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer {
    background-color: #fff;
    z-index: 1;
    font-family: Open Sans, sans-serif;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer div.ot-btn-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  body #onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer div.ot-btn-container .ot-btn-subcntr {
    display: flex;
    width: auto;
    max-width: unset;
    flex-shrink: 0;
    float: none;
  }
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn) {
    background-color: #047a9c;
    min-height: 42px;
    width: auto;
    min-width: unset;
    max-width: unset;
    border: 2px solid #047a9c;
    margin: 0;
    padding: 10px 20px;
    transition: all 0.135s ease;
    border-radius: 30px;
  }
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn).onetrust-close-btn-handler:only-child {
    width: 100% !important;
  }
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn)#accept-recommended-btn-handler {
    margin-left: 15px;
  }
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn):hover {
    opacity: 1;
    color: #fff;
    background-color: #047a9c;
    outline: 0;
  }
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn):focus {
    opacity: 1;
    color: #fff;
    background-color: #047a9c;
    outline: 1px solid #4fa8ff;
  }
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn)#accept-recommended-btn-handler,
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn).ot-pc-refuse-all-handler {
    background-color: #047a9c;
    color: #fff;
  }
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn)#accept-recommended-btn-handler:focus,
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn)#accept-recommended-btn-handler:hover,
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn).ot-pc-refuse-all-handler:focus,
  body
    #onetrust-consent-sdk
    div#onetrust-pc-sdk
    div.ot-pc-footer
    div.ot-btn-container
    button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
      .ot-obj-leg-btn-handler
    ):not([aria-expanded]):not(.ot-link-btn).ot-pc-refuse-all-handler:hover {
    background-color: #047a9c;
    color: #fff;
  }
  @media (max-width: 763px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk {
      display: flex;
      flex-flow: column;
      height: 100%;
      max-height: 100%;
    }
    body #onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer {
      position: relative;
    }
  }
  @media (max-width: 480px) {
    body #onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-header h2#ot-pc-title {
      font-size: 16px;
      line-height: 1.75;
    }
    body #onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-header h2#ot-pc-title:after,
    body #onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-header h2#ot-pc-title:before {
      display: none;
    }
    body #onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer div.ot-btn-container {
      display: block;
    }
    body
      #onetrust-consent-sdk
      div#onetrust-pc-sdk
      div.ot-pc-footer
      div.ot-btn-container
      button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
        .ot-obj-leg-btn-handler
      ):not([aria-expanded]):not(.ot-link-btn) {
      display: block;
      width: 100%;
      margin-top: 15px;
    }
    body
      #onetrust-consent-sdk
      div#onetrust-pc-sdk
      div.ot-pc-footer
      div.ot-btn-container
      button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
        .ot-obj-leg-btn-handler
      ):not([aria-expanded]):not(.ot-link-btn)#accept-recommended-btn-handler {
      margin-left: 0;
    }
    body
      #onetrust-consent-sdk
      div#onetrust-pc-sdk
      div.ot-pc-footer
      div.ot-btn-container
      button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(
        .ot-obj-leg-btn-handler
      ):not([aria-expanded]):not(.ot-link-btn).save-preference-btn-handler {
      margin-top: 0;
    }
    body #onetrust-consent-sdk div#onetrust-pc-sdk div.ot-pc-footer div.ot-btn-container div.ot-btn-subcntr {
      display: block;
    }
  }
  body #onetrust-consent-sdk .ot-close-icon {
    background-size: 12px;
  }
  #onetrust-pc-sdk #ot-lst-cnt .ot-chkbox label:before,
  #onetrust-pc-sdk .ot-chkbox label:before {
    height: 16px !important;
    width: 16px !important;
  }
  #onetrust-pc-sdk #ot-lst-cnt .ot-chkbox label:after,
  #onetrust-pc-sdk .ot-chkbox label:after {
    left: 3px !important;
    top: 4px !important;
  }
  #onetrust-pc-sdk #ot-selall-hostcntr.line-through label:after {
    left: 4px !important;
    top: 8px !important;
  }
  
  body #onetrust-pc-sdk .ot-accordion-layout.ot-cat-item {
    background-color: #f5f5f5 !important;
  }
  
  body #onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob::before {
    background-color: #fff !important;
    border-color: #fff !important;
  }
  