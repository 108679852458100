@import "../../../variables";

.scroll-to-top {
    display: flex;
    position: sticky;
    width: 100%;
    height: 0;
    top: auto;
    bottom: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    transition: opacity 0.15s ease-in-out 0s;
    cursor: pointer;
    z-index: 1;
    .scroll-content {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: none;
        border: 1px solid #151515;
        transition: all 0.15s ease-in-out 0s;
        background-color: #151515;
        position: absolute;
        right: 30px;
        bottom: 30px;
        padding: 0;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }

        .scroll-top-arrow {
            background: url("../../images/scroll-top-arrow.svg") no-repeat scroll 0 0;
            width: 12px;
            height: 7px;
            display: block;
        }
        span {
            color: #fff;
            font-size: 10px;
            font-weight: 700;
            line-height: 21px;
        }
        &:focus-visible {
            outline: 2px auto #000 !important;
            outline-offset: 4px !important;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 600px) {
    .scroll-content {
        height: 30px !important;
        width: 30px !important;
        .top-txt {
            display: none;
        }
    }
}
