@import "../../../variables";

.modal-header .close {
    padding: 0;
    margin: 0;
    color: $text-primary;
    opacity: 1;
    font-size: 24px;
    position: absolute;
    top: 15px;
    right: 18px;
}

.modal-dialog {
    display: flex;
    justify-content: center;
}

.modal-content {
    display: flex;
    justify-content: center;
    border-radius: 8px;
}
.share-modal-wr {
    box-sizing: border-box;
    padding: 60px;
    .copy-txt {
        margin-left: 10px;
    }
    #after-copy-alert {
        border: 1px solid #dbdbdb;
        width: 100%;
        height: 40px;
        padding: 0 !important;
        margin-bottom: 30px !important;
        .vertical-line {
            background-color: $success-color;
            height: 40px;
            width: 4px;
        }
        .alert-icon {
            background: url("../../images/alert-success.svg") no-repeat;
            height: 16px;
            width: 16px;
            margin-left: 16px;
        }
        .close {
            display: none;
        }
    }
    h2 {
        @include product-info(24px, 400, 32px);
        margin-bottom: 30px;
        color: $text-primary;
    }
    p {
        margin-bottom: 30px;
        color: $text-color-secondary;
        @include product-info(16px, 400, 26px);
        word-break: break-word;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .instruction-txt {
        margin-bottom: 6px;
        font-weight: 400;
    }
    b {
        font-weight: 600;
    }
    .modal-header {
        padding: 0;
        border-bottom: none;
    }
    .instruction-container {
        border: 1px solid $input-border-01;
        padding: 11px 13px;
        border-radius: 4px;
        margin-bottom: 30px;
    }
    .secondary-btn {
        width: 100%;
        text-decoration: none;
    }
    .after-copy-alert {
        display: none;
    }
    #go-to-form-btn {
        cursor: pointer;
        text-decoration: none;
    }
}

@media (min-width: 992px) {
    .modal-dialog {
        max-width: 570px !important;
    }
}

@media (max-width: 992px) {
    .modal-dialog {
        max-width: 492px !important;
    }
    .share-modal-wr {
        padding: 45px;
    }
}

@media (max-width: 600px) {
    .modal-dialog {
        max-width: 345px !important;
        margin: 0 auto !important;
    }
    .share-modal-wr {
        padding: 30px;
        #after-copy-alert {
            margin-top: 25px !important;
        }
    }
}

@media (max-width: 345px) {
    #after-copy-alert {
        height: 66px !important;
        .vertical-line {
            height: 66px !important;
        }
    }
}
