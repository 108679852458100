@import "../../../variables";

.tool-tip-btn {
    background: url("../../images/tooltip-icon.png") no-repeat scroll 0 0 !important;
    border: none;
    width: 20px;
    padding: 0;
    height: 18px;
    margin-left: 2px;
    margin-top: -6px;
    position: relative;
}

.tooltip {
    display: inline-block;
    font-weight: 400;
    color: $text-color-secondary;
    font-size: 13px;
    opacity: 0;
    z-index: 1 !important;

    .btn-tooltip-txt {
        max-width: 160px;
        line-height: 20px;
        font-size: 13px;
        color: $text-color-secondary;
    }

    &.show {
        opacity: 1 !important;
    }
}
#tool-tip-triger, #tool-tip-triger2 {
    &:focus {
        outline: 2px solid #000 !important;
        outline-offset: 4px;
        border-radius: 1em;
    }
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0;
    border-bottom-color: transparent;
    display: none;
}
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0 !important;
}

#info-button-tooltip {
    .tooltip-inner {
        padding: 10px;
        text-align: left !important;
        color: #333;
        font-size: 13px;
        background-color: white;
        border-radius: 4px;
        border: 1px solid $input-border-01;
        position: absolute;
        top: 15px;
        right: -13px;
        min-width: 200px;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: -6px;
            right: 20px;
            width: 10px;
            height: 10px;
            background: #ffffff;
            border-right: 1px solid #cad5e0;
            border-bottom: 1px solid #cad5e0;
            -moz-transform: rotate(225deg);
            -webkit-transform: rotate(225deg);
        }
    }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
    .tool-tip-btn {
        background: url("../../images/tooltip-icon-white.png") no-repeat scroll 0 0 !important;
    }
}
