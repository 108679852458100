@import "../../../variables";

.pdp-container {
  margin-top: 90px;
  min-height: 100vh;
}
.pdp-wrap {
  padding-top: 45px;
  margin: 0 auto !important;
  &.skipNavOnpressEnter {
    padding-top: 135px !important;
  }
  .navbar-nav {
    display: inline-block;
    background: #ffffff;
  }
  .sticky-top {
    background: #ffffff;
    color: $text-color-secondary;
    padding: 0;
    border-bottom: 1px solid;
  }
  .pdp-leftside {
    max-width: 750px;
  }
  .pdp-rightside-wr {
    width: 100%;
    margin-left: 5px;
    background: white;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    padding: 30px;
    margin-bottom: 30px;
    display: inline-block;
    &.offer-wr {
      padding: 30px;
    }
    &.access-activated-pdp-wr {
      border-radius: 0 0 8px 8px;
      margin-top: -5px;
      box-shadow: none;
    }
    &.access-activated-wr {
      background-color: $label-bg;
      border-radius: 8px 8px 0 0;
      padding: 10px 30px;
      margin-bottom: 0;
      border: 1px solid $label-bg;
      p {
        color: #fff;
        @include product-info(16px, 700, 26px);
        margin-left: 10px;
      }
    }
    .verify-instructor {
      margin-top: 30px;
      &.k-12-edge-case {
        .vertical-line {
          background-color: $warning-color;
          padding-right: 2px;
          height: 121px;
        }
        .alert-icon-outlined {
          background: url("../../images/warning-icon-outlined.svg") 0 0 no-repeat;
          padding-right: 20px;
          margin: 13px 0 0 10px;
        }
      }
      .vertical-line {
        width: 4px;
        background-color: #003057;
        border-radius: 2px;
        height: 58px;
      }
      .notification-txt {
        @include product-info(14px, 400, 21px);
        margin-top: 10px;
        color: $text-color-secondary;
      }
      .alert-icon-outlined {
        background: url("../../images/alert-icon-outlined.svg") center center scroll;
        height: 16px;
        width: 26px;
        margin: 12px 10px 0;
        background-size: 100% 100%;
      }
    }

    .pdp-btn-container {
      min-width: 307px;
      .custom-btn {
        width: 100%;
        box-sizing: border-box;
        padding: 12px 1.66em;
        &.outline-btn {
          margin-top: 29px;
        }
      }
    }

    .related-product-title {
      font-size: 30px;
      line-height: 38px;
      font-weight: 300;
      margin-bottom: 20px;
      color: $text-primary;
    }
  }
}

.alert.youtube {
  background-color: $notification-bg;
  border-color: $notification-bg;

  .notification-txt {
      @include product-info(16px, 400, 21px);
      padding: 13px;
      color: $text-color-secondary;
  }
  button {
    margin: 0 auto 13px;
    font-weight: 400;
    width: 200px;
  }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pdp-rightside-wr {
    width: 80% !important;
  }
  .pdp-btn-container {
    min-width: 248px !important;
  }
  .pdp-wrap {
    display: flex !important;
    justify-content: center;
  }
}

@media (min-width: 1220px) {
  .pdp-wrap {
    max-width: 1230px;
  }
}

@media (max-width: 600px) {
  .pdp-wrap {
    .pdp-btn-container {
      min-width: 180px !important;
    }
  }
}

@media (max-width: 991px) {
  .pdp-wrap {
    padding: 30px 15px 45px !important;
    display: flex !important;
    justify-content: center;
    .pdp-leftside {
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
    }
    .pdp-rightside {
      padding-left: 0;
      padding-right: 0;
    }
    .pdp-rightside-wr {
      box-sizing: border-box;
      margin-left: 0;
    }
    .mobile-component {
      .mobile-title-section {
        h3 {
          @include product-info(40px, 300, 48px);
        }
      }
    }
  }
  .desktop-view-only {
    display: none !important;
  }
  #pdp-menu {
    margin-top: 0 !important;
  }
  .product-header {
    margin-bottom: 30px;
  }
}

@media (max-width: 600px) {
  .pdp-wrap {
    .pdp-btn-container {
      min-width: 180px !important;
    }
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .pdp-btn-container {
    display: flex;
    .custom-btn.outline-btn {
      margin: 0 0 0 29px !important;
    }
  }
  .verify-instructor {
    &.k-12-edge-case {
      .vertical-line {
        height: 60px !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .tab-mobile-view-only {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .pdp-rightside {
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
    box-sizing: border-box;
  }
}

@media (max-width: 1200px) {
  .pdp-container {
    .alert-wr {
      max-width: 1180px;
    }
  }
}

@media (max-width: 400px) {
  .pdp-wrap {
    .verify-instructor {
      &.k-12-edge-case {
        .vertical-line {
          height: 150px !important;
        }
      }
    }
  }
}

@media (max-width: 400px) and (min-width: 600px) {
  .pdp-wrap {
    .verify-instructor {
      &.k-12-edge-case {
        .vertical-line {
          height: 120px !important;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .pdp-wrap {
    .verify-instructor {
      .alert-icon-outlined {
        width: 30px !important;
      }
    }
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .verify-instructor {
    .alert-icon-outlined {
      background: url("../../images/hc-warning-icon-outlined.png") 0 0 no-repeat !important
      ;
    }
    &.k-12-edge-case {
      .alert-icon-outlined {
        background: url("../../images/hc-warning-icon-outlined.png") 0 0 no-repeat !important
        ;
      }
    }
  }
}
