@import "../../../variables";

@mixin institute-img {
  padding-right: 5px;
  margin-top: -6px;
}

@mixin selected-institute-txt {
  font-size: 16px;
  font-weight: 600;
  color: $text-primary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  padding-bottom: 3px;
}

.institute-wrap {
  margin-bottom: 30px;
  min-width: 380px;
  .dropdown {
    float: left;
    position: relative;
    background: none;
    button {
      display: inline-block;
      border: none;
      background: none;
      @include custom-link;
      &.dropdown-toggle {
        background: none;
        padding-bottom: 0 !important;
        padding-left: 0;
        padding-right: 0;
        svg {
          &::after {
            display: none;
          }
        }
      }
      .btn-link {
        color: transparent;
        &:hover {
          color: transparent;
          text-decoration: none;
        }
      }
      &:hover,
      &:active {
        border: none !important;
        background: none !important;
        text-decoration: none !important;
      }
      .institute-img {
        @include institute-img;
      }
      .institute-text {
        @include selected-institute-txt;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .dropdown-menu {
      padding: 10px 0 0;
      color: $text-primary;
      font-weight: 400;
      font-size: 14px;
      border: 1px solid rgb(204, 204, 204);
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
      right: 0;
      left: unset;
      inset: unset !important;
      top: 1px !important;
      z-index: 1;
      width: 100%;
      min-width: 380px;
      border-radius: 4px;
      a {
        text-decoration: none;
        @include custom-link;
      }
    }
    .dropdown-item {
      width: auto;
      word-break: break-word;
      white-space: inherit;
      &.active,
      &:active {
        background: transparent;
        color: $text-primary;
      }
    }
    .add-institution {
      a {
        height: 20px;
        display: inline-block;
        text-decoration: none;
        color: $link-color;
        @include custom-link;
        &:focus-visible {
          outline: 2px auto #000 !important;
          outline-offset: 5px !important;
        }
      }
    }
  }

  #tooltip-wr {
    overflow: visible;
    .tool-tip-btn {
      margin-left: -3px;
      margin-top: 6px;
    }
  }

  .show {
    button {
      &::after {
        background: #ffffff url(../../images/institution-arrow.svg) no-repeat scroll 0 0 !important;
        transform: rotate(180deg);
      }
    }
  }
  .btn-success:not(:disabled):not(.disabled):active:focus,
  .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus,
  .btn-success:not(:disabled):not(.disabled):active,
  .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .picklist-popup {
    font-size: 14px;
    position: absolute;
    right: 14px;
    .popup-icon {
      background: #ffffff url(../../images/pop-up.svg) no-repeat scroll 0 0;
      width: 18px;
      height: 28px;
      float: right;
      margin-top: 8px;
      border: 0;
    }
  }

  .checkmarkPop {
    width: 285px;
    text-align: left;
    padding: 20px 25px 15px 25px;
    background: #d6ebe8;
    font-weight: 400;
    z-index: 9;
    min-height: 125px;
    position: relative;
    margin-right: 0;
    margin-top: 25px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    float: left;
    margin-left: -260px;
    &::before {
      border-color: rgba(194, 225, 245, 0);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 10px solid #d6ebe8;
      border-width: 10px;
      margin-left: -8px;
      bottom: 100%;
      left: 260px;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    .pop-text {
      font-weight: 700;
      line-height: 22px;
      padding: 0 0 18px 0;
    }
    .close-popup {
      text-decoration: underline;
      cursor: pointer;
      margin: 10px 0;
      float: right;
    }
  }
  .mobile-institutepicker {
    button {
      svg {
        height: 24px;
        width: 24px;
      }
    }
    p {
      @include product-info(18px, 300, 18px);
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #dbdbdb;
    }
  }
}

.lms-flow-wr {
  display: inline-block;
  .lms-flow {
    margin-bottom: 30px;
    .lms-institute-img {
      @include institute-img;
    }
    .institute-text {
      @include selected-institute-txt;
    }
  }
}

.serp-container {
  .institute-wrap {
    justify-content: space-between;
  }
}

@media (min-width: 992px) and (max-width: 1110px) {
  .institute-wrap {
    min-width: 340px !important;
  }
  .dropdown {
    button {
      p {
        max-width: 260px !important;
      }
    }
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .hc-mode-institute {
    fill: #fff;
  }
}

@media (max-width: 992px) {
  .search-institute-wrap {
    .institute-wrap {
      margin-bottom: 40px !important;
    }
  }

  .pdp-container {
    .institute-wrap {
      margin-bottom: 30px !important;
    }
  }
}
@media (min-width: 992px) {
  .institute-wrap {
    justify-content: space-between;
  }
}
@media (min-width: 600px) {
  .mobile-institutepicker {
    display: none;
  }
  .institute-wrap {
    .dropdown {
      .add-institution {
        border-top: 1px solid #d8d8d8;
        padding: 15px 15px 15px 46px;
      }
    }
    .selected-institute,
    .dropdown-item {
      padding: 10px 15px 10px 46px;
    }
    .selected-institute {
      background: #ffffff url(../../images/selected-right.svg) no-repeat scroll 20px 7px;
    }
  }
}
@media (max-width: 600px) {
  .institute-wrap {
    margin-bottom: 30px;
    min-width: 100%;
    &.institute-wrap-alert {
      .dropdown {
        .dropdown-menu {
          top: -205px !important;
        }
      }
    }
    .dropdown {
      .dropdown-menu {
        padding: 30px 20px 10px;
        right: 0 !important;
        left: 1px !important;
        top: -155px !important;
        z-index: 1;
        width: 100%;
        border-radius: 0 0 6px 6px;
        min-width: 100vw;
        box-sizing: border-box;
      }
      .selected-institute {
        background: #ffffff url(../../images/selected-right.svg) no-repeat scroll 1px 7px;
      }
    }
    .dropdown-close {
      display: none;
    }
    .selected-institute,
    .add-institution,
    .dropdown-item {
      padding: 10px 15px 10px 26px;
    }

    .institute-text {
      max-width: calc(100% - 150px) !important;
      min-width: 200px;
    }
    .tool-tip-btn {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .pdp-container {
    .institute-wrap {
      justify-content: space-between;
      margin-top: 0 !important;
      .dropdown {
        width: 50%;
      }
      #tooltip-wr {
        width: 50%;
        .tool-tip-btn {
          float: right;
        }
      }
      .dropdown-menu {
        z-index: 3;
      }
    }
  }
}

@media (max-width: 370px) {
  .institute-wrap {
    .institute-text {
      max-width: calc(100% - 245px) !important;
      min-width: 160px;
    }
  }
}

@media (max-width: 370px) {
  .institute-wrap {
    .institute-text {
      max-width: calc(100% - 245px) !important;
      min-width: 160px;
    }
  }
}

@media (max-width: 470px) {
  .institute-wrap {
    &.institute-wrap-alert {
      .dropdown {
        .dropdown-menu {
          top: -225px !important;
        }
      }
    }
  }
}
