@import "../../../variables";

.serp-wrap {
  .search-institute-wrap {
    justify-content: flex-end;
    .institute-wrap {
      margin-bottom: 20px;

      #tooltip-wr {
        .tool-tip-btn {
          margin-right: -7px;
          margin-top: 6px;
        }
      }
    }
  }

  h1 {
    @include product-info(24px, 300, 33px);
    color: $text-primary;
    margin: 0;
    word-break: break-word;

    .searched-txt {
      font-weight: 600;
    }
  }

  .serp-content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list-grid-icons {
    a {
      @include custom-link;
    }
  }

  .grid-icon,
  .list-icon {
    display: table-cell;
    width: 24px;
    height: 24px;
    float: left;
    margin-left: 30px;
  }

  .show-results {
    @include product-info(14px, 700, 21px);
    margin: 20px 0;
    color: $text-primary;
  }

  .grid-icon {
    float: right;

    &:hover {
      cursor: pointer;
    }
  }

  .list-icon {
    float: left;

    a {
      @include custom-link;
    }
  }

  .view-text {
    border: none;
    padding: 0;
    color: transparent;
    background: none;
  }

  .serp-products {
    text-align: center;

    &.pdp-learning-apps {
      .praduct-wrap {
        height: 173px;
        width: 173px;
      }
    }

    a {
      text-decoration: none;
      @include custom-link;
    }

    .praduct-wrap {
      height: 196px;
      width: 100%;
      max-width: 173px;
      position: relative;
      img {
        height: 100%;
        width: 100%;
        text-align: center;
        border-radius: 10px;
      }

      &.list-learning-app {
        img {
          padding-top: 0;
        }
      }
    }

    .product-title-serp {
      
      @include product-info(16px, 600, 26px);
      margin-bottom: 10px;

      a {
        color: $link-color;
        @include custom-link;
      }
      &.list-title a{
        margin-left: 2px;
      }
      &.title-grid-view a{
        &:focus-visible {
          outline-offset: -1px !important;
        }
      }
    }
    
    .serp-product-infor {
      text-align: left;
      line-height: 21px;
      color: $text-sub;
      padding-top: 20px;

      .product-ed p, .product-authors p, .product-description p, .d-flex.k12-product p {
        margin-left: 2px !important;
      }

      .product-ed {
        p {
          @include product-info(14px, 700, 21px);
          color: $text-sub;
        }
      }
      
      .product-authors {
        min-height: 26px;
        height: auto;
        overflow: hidden;
      }

      .product-description,
      .product-authors {
        p {
          @include product-info(14px, 400, 21px);
          color: $text-sub;
        }
      }

      .product-isbn {
        p {
          @include product-info(14px, 400, 21px);
          color: $text-sub;
        }
      }
      .product-ed,
      .product-isbn {
        min-height: 21px;
      }
      .product-isbn {
        padding: 10px 0 5px;
      }
    }

    .k12label-wr {
      display: inline-flex;
      border: 1px solid $k12-license-activated;
      color: $text-color-secondary;
      @include product-info(12px, 600, 12px);
      border-radius: 100px;
      padding:7px 15px;
      &.purchase-wr {
        border: 1px solid #007fa3;
      }

      span {
        padding-left: 8px;
        display: inline-block;
      }
    }
  }

  /*---grid view---*/
  .grid-view {
    max-width: 1230px;
    .serp-products {
      min-height: 430px;
      margin-bottom: 45px;

      &.custom-grid-view {
        max-width: 173px;
        padding-right: 48px;
        .k12label-wr {
          margin-top: 10px;
        }
        .brand {
          min-height: 42px;
        }
        &.pdp-learning-apps {
          margin-top: 23px;
        }

        &:nth-child(4n) {
          padding-right: 0;
        }
      }

      .author-grid-view {
        height: auto;
        min-height: 42px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .title-grid-view {
        min-height: 52px;
        height: auto;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
      }
      .k12label-wr {
        width: 87%;
        &.purchase-wr {
          width: 89%;
        }
      }
    }
  }

  /*---List view-----*/
  .list-view {
    list-style-type: none;
    .custom-list-view {
      &.pdp-learning-apps {
        .serp-list-overlayer {
          top: -78px;
        }
      }
    }
    .serp-products {
      padding-bottom: 30px;

      &:nth-last-child() {
        padding-bottom: 45px;
      }

      .serp-product-infor {
        padding: 0;
        margin-left: 5px;

        &.list-view-info {
          .list-title {
            min-height: 26px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .product-authors {
            min-height: 21px;
            padding: 5px 0;
          }

          .product-description {
            padding-bottom: 5px;
            height: auto;
            min-height: 42px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      .k12label-wr {
        width: max-content;
      }
    }
  }

  .search-result-view {
    h1,
    h2 {
      &:focus-visible {
        box-shadow: none;
        outline: 2px solid #000 !important;
        outline-offset: 4px;
      }
    }
  }

  /*---No search result---*/
  .no-search-result-wr {
    margin-top: 20px;
    h6 {
      @include product-info(18px, 600, 28px);
      color: $text-primary;
      margin: 0 0 10px;
    }
    ul {
      margin-left: 28px;
      li {
        @include product-info(16px, 400, 26px);
        color: $text-primary;
      }
    }
    .no-result-btn {
      padding: 16px 27px;
      margin: 50px 0;
    }
  }
}

/*--overlayers --*/
.overlay-cbadge-wrapper {
  position: relative;
}
.overlay-badge {
  border-top-left-radius: 9px;
  position: absolute;
  z-index: 0;
  opacity: 0.95;
  top: -196px;
  left: 0;
  height: 60px;
  width: 70px;
  background-color: #e6e6e6;

  &.serp-list-overlayer {
    top: -87px;
    left: 0;
  }

  &:after,
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #e6e6e6 transparent transparent transparent;
    position: absolute;
  }
  &:before {
    top: 0;
    left: 69px;
    border-width: 60px 70px 0 0;
  }
  &:after {
    top: 59px;
    left: 0px;
    border-width: 60px 70px 0 0;
  }
  &.pdp-learning-apps-overlayer {
    top: -196px;
    border-top-left-radius: 9px;
  }
}

.overlay-text {
  @include product-info(24px, 400, 22px);
  position: absolute;
  z-index: 70;
  top: 22px;
  left: 4px;
  text-align: center;
  color: #000;
  white-space: normal;
  transform: rotate(-40deg);
  -webkit-transform: rotate(-40deg);

  /* Safari */
  -webkit-transform: rotate(-40deg);

  /* Firefox */
  -moz-transform: rotate(-40deg);

  /* IE */
  -ms-transform: rotate(-40deg);

  /* Opera */
  -o-transform: rotate(-40deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

  .sub-text {
    @include product-info(14px, 400, 21px);
    position: absolute;
    left: -1px;
    width: 100%;
  }
  &.class-test {
    width: 106px;
    z-index: 70;
    top: 36px;
    left: 0px;
  }
  &.sample {
    top: 27px;
    left: 2px;
  }
}

@media (min-width: 992px) and (max-width: 1136px) {
  .grid-view {
    .serp-products {
      &.custom-grid-view {
        max-width: 136px !important;
      }
    }
  }
  .overlay-badge {
    width: 40px;
    height: 40px;
    .overlay-text {
      font-size: 19px;
      top: 14px;
      left: 5px;
      .sub-text {
        font-size: 12px;
      }
      &.class-test {
        top: 29px;
        left: -13px;
      }
    }
    &::before {
      left: 39px;
      border-width: 65px 70px 0 0;
    }
    &::after {
      top: 39px;
      border-width: 60px 68px 0 0;
    }
  }
}
// @media (min-width: 1137px){
//   .grid-view {.k12label-wr{
//     max-wid
//   }
// }

// }

@media (min-width: 1137px) and (max-width: 1199.98px) {
  .grid-view {
    .serp-products {
      &.custom-grid-view {
        max-width: 143px !important;
      }
      .praduct-wrap {
        max-width: 146px;
        width: 146px;
      }
      .title-grid-view {
        max-width: 146px;
      }
      .product-title-serp {
        a {
          max-width: 146px;
        }
      }
    }
  }
  .praduct-wrap {
    img {
      max-width: 146px;
      width: 146px !important;
    }
  }
}

@media (max-width: 992px) {
  .filter-by-wr {
    display: flex;
    flex-direction: row;
    background: white;
    border: none;
    padding: 12px 22px;
    @include product-info(13px, 600, 18px);
    &.no-srp-result {
      float: right;
    }
  }
  .serp-container {
    box-sizing: border-box;
  }
  .serp-wrap {
    .search-institute-wrap {
      #tooltip-wr {
        .tool-tip-btn {
          margin-right: 6px !important;
        }
      }
    }
  }
  #info-button-tooltip .tooltip-inner {
    right: -15px;
  }
  .list-grid-icons-wr {
    display: none;
  }
  .serp-leftbar {
    display: none;
  }

  .facet-open {
    display: block !important;
    z-index: 1;
    top: 0;
    width: 100%;
  }

  .serp-container-rightside {
    padding: 0 15px !important;
  }
  .list-view {
    .serp-products {
      box-sizing: border-box;
      .serp-product-infor {
        width: calc(100% - 208px);
      }
    }
  }
  .serp-leftbar .card-header button,
  .serp-leftbar .applied-filter-container .applied-filter-wr {
    max-width: 100%;
  }
  @media (-ms-high-contrast: active), (forced-colors: active) {
    .filter-by-wr {
      border: 1px solid !important;
    }
    .k12label-wr {
      border: 1px solid #fff !important;
    }
  }
}

@media (max-width: 600px) {
  .filter-by-wr {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    box-sizing: border-box;
    &.no-srp-result {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  .serp-content {
    flex-direction: column;
  }
  .search-institute-wrap {
    justify-content: center !important;
    .institute-wrap {
      justify-content: center !important;
    }
  }

  .list-view {
    .serp-products {
      box-sizing: border-box;
      padding-bottom: 20px !important;
      border-bottom: 1px solid #e8eefa;
      margin-right: 0;
      margin-left: 0;

      .praduct-wrap {
        height: 120px;
        width: 90px;
        padding-left: 0;
        padding-right: 0;
      }
      .serp-product-infor {
        width: calc(100% - 115px) !important;
        margin-left: 20px !important;
      }
    }
    li {
      &:not(:first-child) {
        padding-top: 20px;
      }
    }
  }

  .overlay-badge {
    top: -196px;
    left: 0;
    height: 30px;
    width: 30px;
    &.serp-list-overlayer {
      top: -49px;
      left: 0;
      .overlay-text {
        font-size: 8px;
        line-height: 12px;
        left: 8px;
        top: 12px;
        &.sample {
          top: 12px;
          left: 4px;
        }
        &.class-test {
          left: -26px;
          top: 17px;
        }
        .sub-text {
          font-size: 5px;
          font-weight: 400;
          line-height: 8px;
        }
      }
    }
    &::before {
      top: 0;
      left: 29px;
      border-width: 35px 45px 0 0;
    }
    &::after {
      top: 29px;
      left: 0px;
      border-width: 30px 35px 0 0;
    }
  }
  .search-result-view {
    width: 100%;
  }
  .serp-wrap {
    .search-institute-wrap {
      max-width: 600px;
      display: block !important;
      .institute-wrap {
        width: 100%;

        margin-top: 30px;
        margin-bottom: 40px;
        .dropdown {
          width: 50%;
          button {
            margin-right: 5px;
            .institute-img {
              margin-right: 5px;
            }
          }
        }
        #tooltip-wr {
          width: 50%;
          margin-right: 13px;
          .tool-tip-btn {
            float: right;
            right: 0 !important;
          }
        }
      }
    }
    #tooltip-wr {
      width: 50%;
      margin-right: 13px;
      .tool-tip-btn {
        float: right;
      }
    }
  }

  .facet-open {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
  }
  .serp-container {
    padding-top: 0 !important;
    .serp-container-leftside {
      max-width: 100% !important;
      padding: 0 !important;
    }
  }
}

@media (min-width: 992px) {
  .filter-by-wr {
    display: none;
  }
  .serp-content {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 600px) {
  .k12-product {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media (min-width: 600px) and (max-width: 992px) {
  .serp-container {
    .serp-container-leftside {
      position: absolute !important;
      z-index: 1;
      right: 15px;
      padding-left: 0;
      padding-right: 0;
      &.tab-filter-wr {
        margin-top: 50px;
      }
    }
  }

  .addMarginSerp {
    margin-top: 50px !important;
  }
}

@media (max-width: 992px) {
  .facet-open {
    z-index: 2;
  }
}

@media (max-width: 600px) {
  .serp-wrap .serp-products .product-title-serp {
    line-height: 24px !important;
  }
  .k12-product {
    flex-direction: column;
    .k12label-wr {
      margin-top: 10px;
    }
  }
}
@media (max-width: 600px) {
  .institute-wrap .dropdown button.dropdown-toggle {
    width: 100%;
    min-width: 255px;
  }
}
