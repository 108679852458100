@media (max-width: 992px) {
    .tab-mobile-search-wr {
      padding: 15px 15px 0;
      .searcbar-wrap {
        margin: 0;
        max-width: 100%;
        input.searchdbar{
          max-width: 100%;
        }
        .searchdbar-active {
          border-radius: 20px !important;
        }
        .search-dropdown {
          border: none;
          box-shadow: none;
          margin: 10px 0 0;
          width: calc(100% - -28px);
          padding-left: 5px;
          .search-product-authors {
            &:last-child {
              padding-bottom: 10px;
            }
          }
          .list-group-search-result {
            padding: 0;
          }
        }
        .searchbar-close-icon {
          display: none;
        }
      }
      .search-close-responsive {
        padding: 0 0 0 15px;
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }