// Colors
$primary-btn-color: #ffb81c;
$disabled-btn-color: #e6e6e6;
$warning-color: #db0020;
$success-color: #008638;
$alert-color: #003057;
$input-border-01: #767676;
$input-border-02: #dbdbdb;
$selection-btn: #3497ff;
$link-color: #006f8f;

$main-bg: #fafafa;
$pill-bg: #e6e6e6;
$notification-bg: #effbfc;
$label-bg: #722351;
$k12-license-activated:#84BD00;
$horizontal-line:#E6E6E6;
$access-titles:#151515;

// Text colors
$text-primary: #151515;
$text-color-secondary: #333333;
$text-link: #005a70;
$text-sub: #505759;

@mixin product-infor {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding-left: 20px;
}
@mixin product-title($size) {
  font-weight: 700;
  color: $text-color-secondary;
  font-size: $size;
  line-height: 38px;
  margin: 0;
  padding-bottom: 15px;
}
@mixin product-isbn {
  color: $text-sub;
  span {
    font-weight: 700;
  }
}
@mixin custom-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:active,
  &:focus {
    border-color: transparent;
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  &:focus-visible {
    outline: 2px auto #000 !important;
    outline-offset: 1px !important;
  }
}
@mixin product-info($fontSize, $fontWeight, $lineHeight) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

@mixin filter-wr {
  margin: 0;
  padding: 11px 13px;
  @include product-info(16px, 400, 26px);
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px !important;
  width: 100%;
  text-align: left;
  text-decoration: none;
  max-height: 48px;
  color: $text-primary;
  &:focus {
    box-shadow: none;
  }
}

@mixin box-shadow {
  box-shadow: 0 1.6px 8.3px 0 rgba(28, 34, 46, 0.2);
}
