@import "../../../variables";
.platform {
  padding: 20px 0 5px;
  .form-check {
    padding-bottom: 22px;
    &:last-child {
      padding-bottom: 12px;
    }
  }
  .form-check-label {
    padding-left: 17px;
    margin-top: 2px;
    .platform-facet-txt {
      @include product-info(16px, 400, 26px);
      color: $text-primary !important;
      margin-top: 3px;
      sup {
        top: -6px;
        left: -2px;
        @include product-info(10px, 400, 15px);
      }
    }
  }
  .form-check-input {
    border: 2px solid $input-border-01;
    height: 18px;
    width: 18px;
    outline: 0 !important;
    -webkit-appearance: none;
    border-radius: 50%;
    &.input-platform {
      margin-top: 6px;
    }
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      margin: 2px;
      border-radius: 50%;
    }
    &:checked {
      border-color: $selection-btn;
      &::before {
        background: $selection-btn;
      }
    }
  }
}
