@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../variables";

/* The following block can be included in a custom.scss */

/* make the customizations */

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active{
  background-color: inherit;
  border-color: inherit;
}

body {
  font-family:"Open Sans", serif;
  font-weight:300;line-height:1.2;color:$text-primary;
  text-rendering:optimizeLegibility;
  -webkit-font-feature-settings:"lnum" 1;
  font-feature-settings:"lnum" 1;
  -webkit-text-size-adjust:100%;
  -ms-text-size-adjust:100%;
  direction:ltr;
  background-color:#fafafa;
  opacity:1;
  position:relative;
  padding:0;
  margin:0;
  overflow-x:hidden;
}

a, a:hover, a:focus {
  color: $text-primary;
  &:hover {
    text-decoration: underline;
  }
}

.list-group {
  border-radius: 0;
}

.list-group-item {
  padding: 0;
  border: none;
}