@import "scss/custom";
@import "./styles.scss";

html {
    scroll-behavior: smooth;
}
header {
    margin-bottom: 0 !important;
}
.header-wr {
    background-color: white;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    padding-right: 0;
}
.header-container {
    max-width: 1200px;
    padding: 0;

    @media screen and (min-width: 1200px) {
        max-width: 1213px;
    }
}

.container {
    width: 100%;
    margin: 0 auto;
}

.header-nav {
    padding-right: 0 !important;
}
.console-header {
    z-index: 1;

    .header-section {
        picture {
            &:focus-visible {
                box-shadow: none;
                outline: 2px solid #000 !important;
                outline-offset: 4px;
            }
        }
    }
    .header-nav-items {
        .my-account-menu {
            font-family: "Open Sans", serif !important;
            top: 59px !important;
            li {
                font-size: 14px;
                &:nth-child(2) {
                    padding-top: 15px;
                }
                a,
                h2 {
                    color: #000;
                }
                h2 {
                    border-bottom: 1px solid #d8d8d8;
                    padding-top: 4px;
                }
                a {
                    &:focus-visible {
                        box-shadow: none;
                        outline: 2px solid #000 !important;
                        outline-offset: 4px;
                    }
                    &:focus {
                        box-shadow: none;
                        outline: 2px solid #000 !important;
                        outline-offset: 4px;
                        &::after {
                            border: none !important;
                        }
                    }
                }
            }
        }
        .header-nav-item {
            &.profile {
                .profile-button {
                    color: #151515;
                    border: 2px solid #151515 !important;
                    &:hover &:active {
                        color: #fff !important;
                    }
                    &.signed-in {
                        &:focus {
                            outline: none !important;
                        }
                    }
                    &.signed-in{
                        &:focus-visible {
                            outline: none !important;
                        }
                    }
                }
            }
        }

        .MuiButton-label {
            font-weight: 600;
            font-family: "Open Sans", serif !important;
        }
    }
}
.pe-icon--btn {
    &:focus {
        &::after {
            border: none !important;
        }
    }
}

.console-header-fixed {
    z-index: 2 !important;
    .header-section {
        z-index: 3 !important;
    }
    header img {
        margin-left: 30px;
    }
}
#iph-container .zdLayout.fixed.default {
    top: 71px;

    @media (min-width: 1130px) and (max-width: 1440px) {
        right: 130px;
    }
    @media (min-width: 1441px) and (max-width: 2300px) {
        right: 500px;
    }
}
// Zoomin compatibility fix
#iph-container .zdLayout.fixed.zdIsMobilePhone {
    left: auto;
    top: 92px;
}

.main-bg {
    background-color: $main-bg;
    padding: 0;
}

.custom-pdp {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.drawerMain {
    top: 71px !important;
    height: calc(100vh - 71px) !important;
}
.header-nav-item {
    &.help {
        .MuiIconButton-root {
            padding: 10px;
        }
        .MuiSvgIcon-fontSizeLarge {
            font-size: 1.6rem !important;
        }
        .MuiSvgIcon-root {
            width: 18px;
            height: 18px !important;
        }
    }
}

@media (max-width: 1200px) {
    .pearson-logo {
        margin: 0 30px !important;
    }
}

// Home page & srp page header
@media (min-width: 1010px) {
    .header-srp-container {
        .header-section {
            .header-nav {
                padding-right: 5px !important;
            }
        }
    }
    .header-hm-container {
        .header-section {
            .header-nav {
                padding-right: 28px !important;
            }
        }
    }
}

// pdp page header
@media (min-width: 992px) and (max-width: 1120px) {
    .console-header-fixed {
        .header-section {
            .header-nav {
                position: absolute;
                right: 9%;
            }
        }
        .pearson-logo {
            margin-left: 24px !important;
        }
    }
    .pdp-container {
        .institute-text {
            max-width: 250px !important;
        }
    }
}
/* One Trust Overrides of IES Style sheet - onetrust.scss */

.ot-fltr-scrlcnt.ot-pc-scrollbar {
    padding: 10px !important;
}

.ot-fltr-btns{
    padding-top:17px !important;
}

@media screen and (max-height: 600px) {
    #ot-fltr-cnt{
        max-height: 185px !important;
        overflow-y: scroll !important;
    }
}

@media (max-width: 991px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row 
    #onetrust-accept-btn-handler, body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw 
    .ot-sdk-container .ot-sdk-row #onetrust-accept-btn-handler {
        margin-top: 1.2em;
    }

    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row 
    #onetrust-group-container, body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw 
    .ot-sdk-container .ot-sdk-row #onetrust-group-container {
        margin-bottom: 1.2em;
    }
    #onetrust-banner-sdk #onetrust-policy {
        margin-left: 0.5em !important;
    }

}
@media (max-width: 767px) {
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler, 
    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler, 
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-pc-btn-handler, 
    body #onetrust-consent-sdk #onetrust-banner-sdk.ot-buttons-fw .ot-sdk-container .ot-sdk-row #onetrust-reject-all-handler {
        margin-bottom: 1.2em !important;
        margin-right: 0 !important;
    }

    body #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container .ot-sdk-row #onetrust-button-group button#onetrust-accept-btn-handler {
        margin-right: 0 !important;
    }
}
@media (max-width: 425px) {
    #onetrust-banner-sdk #onetrust-policy {
        margin-left: 0 !important;
    }
}

@media (max-width: 400px) {
    #onetrust-banner-sdk #onetrust-policy {
        margin-left: 0 !important;
    }

    .ot-fltr-btns {
        display: block !important;
    }

    #filter-apply-handler {
        margin-bottom: 10px !important;
    }
}

@media (max-width: 1200px) {
    .pearson-logo {
        margin: 0 30px !important;
    }
}
@media (min-width: 1121px) and (max-width: 1200px) {
    .console-header-fixed {
        .header-section {
            .header-nav {
                padding-right: 85px !important;
                padding-right: calc((100vw - 1010px) / 2) !important;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .pearson-logo {
        margin: 0 26px !important;
    }
    .header-srp-container,
    .header-hm-container {
        .header-nav-items .header-nav-item.profile .signed-in {
            margin-right: 15px !important;
        }
    }
}

@media (max-width: 992px) {
    .console-header {
        padding: 0 15px 0 50px !important;
        .header-nav-item {
            &.help {
                .MuiIconButton-root {
                    padding: 10px 10px 10px 0 !important;
                }
            }
        }
    }

    .console-header-fixed {
        margin-right: 0 !important;
        .header-nav {
            position: absolute;
            right: 70px !important;
        }
    }

    .header-srp-container,
    .header-hm-container {
        .header-nav-items .header-nav-item.profile .signed-in {
            margin-right: 15px !important;
        }
    }

    .custom-pdp {
        .signed-in-wr {
            .console-header-fixed {
                padding: 0 0 0 40px !important;
                .pearson-logo {
                    margin: 0 17px !important;
                }
            }
        }
        .console-header-fixed {
            padding-right: 0 !important;
            .pearson-logo {
                margin: 0 7px !important;
            }
        }
    }
    .pearson-logo {
        margin: 0 22px !important;
    }
}
@media (max-width: 600px) {
    .console-header {
        padding: 0 15px 0 40px !important;
        z-index: 1;
        .header-section {
            margin: 0;
        }
        .header-nav-item {
            &.help {
                padding: 0 !important;
                .MuiIconButton-root {
                    padding: 10px 0 !important;
                }
            }
        }
        .pearson-logo {
            margin: 0 31px !important;
        }
    }

    .custom-pdp {
        .signed-in-wr {
            .console-header-fixed {
                .pearson-logo {
                    margin: 0 30px !important;
                }
            }
        }
        .console-header-fixed {
            margin-right: 0 !important;

            .header-section {
                margin: 0 !important;
            }
            .pearson-logo {
                margin: 0 31px !important;
            }
            .header-nav {
                right: 60px !important;
            }
        }
    }
}

@media (min-width: 1200px) {
    .console-header {
        padding: 0 10px 0 80px !important;
    }
    .console-header-fixed .header-section .header-nav {
        padding-right: calc(50vw - 527px) !important;
    }
    .signed-in-wr {
        .console-header-fixed .header-section .header-nav {
            padding-right: calc(50vw - 510px) !important;
        }
        .console-header {
            padding: 0 20px 0 80px !important;
        }
    }
}

.header-srp-container,
.header-hm-container {
    .console-header {
        .header-section {
            margin: 0 !important;
        }
    }
}
