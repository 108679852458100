@import "../../../variables";

.serp-leftbar {
  background: #ffffff;
  border: 1px solid #cccccc;
  max-width: 267px !important;
  height: fit-content;
  padding: 20px 15px 5px;
  border-radius: 4px;

  .filter {
    color: $text-primary;
    display: inline-block;
    @include product-info(21px, 400, 30px);
    margin-bottom: 15px;

    &:focus,
    &:focus-visible {
      outline: 0 !important;
    }
  }

  .applied-filter-container {
    margin-bottom: 25px;

    ul {
      list-style-type: none;
    }

    .applied-filter-wr {
      max-width: 197px;
      @include filter-wr;
      margin-bottom: 15px;

      p {
        @include product-info(16px, 400, 26px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 3px;
        color: $text-primary;
      }

      .close-icon {
        border: 0;
        right: 16px;
        width: 26px;
        background: none;
        padding: 0 0;
      }
    }
  }

  .card {
    border: none;
    padding-bottom: 15px;
  }

  .card-header {
    padding: 0;
    border: none;
    background: #fff;

    button {
      @include filter-wr;
      max-width: 197px;

      &.collapsed {
        &::after {
          border: none;
          content: "";
          background: #ffffff url(../../images/filter-arrow-icon.svg) no-repeat scroll 0 0;
          position: absolute;
          right: 15px;
          top: 16px;
          width: 18px;
          height: 18px;
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }

  .card-body {
    padding: 0 0 0 10px;
  }

  .platforms {
    padding: 20px 0;
  }

  .sort-by {
    padding: 20px 0 5px;

    &.platform {
      .form-check {
        padding-bottom: 10px;
      }
    }

    .form-check {
      padding-bottom: 22px;

      &:last-child {
        padding-bottom: 12px;
      }
    }

    .form-check-label {
      @include product-info(16px, 400, 26px);
      color: $text-primary;
      padding-left: 17px;

      .brand {
        margin-top: 0;
      }
    }

    .form-check-input {
      border: 2px solid $input-border-01;
      height: 18px;
      width: 18px;
      outline: 0;
      -webkit-appearance: none;
      border-radius: 50%;

      &.input-platform {
        margin-top: 6px;
      }

      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        margin: 2px;
        border-radius: 50%;
      }

      &:checked {
        border-color: $selection-btn;

        &::before {
          background: $selection-btn;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1050px) {
  .serp-leftbar {
    .card-header {
      button {
        width: 86%;
      }
    }
    .applied-filter-container {
      .applied-filter-wr {
        width: 86%;
      }
    }
  }
}

@media (max-width: 600px) {
  .serp-leftbar {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    border: none;
    max-width: 100% !important;
    .applied-filter-wr,
    .card-header button {
      max-width: 100% !important;
    }
    .filter-close-responsive {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 992px) {
  .filter-close-responsive {
    background: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .filter-close-responsive {
    display: none !important;
  }
  .serp-leftbar .applied-filter-container .applied-filter-wr .close-icon {
    padding-right: 0;
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .serp-leftbar .card-header button.collapsed::after {
    background: #ffffff url(../../images/hc-arrow.svg) no-repeat scroll 0 0 !important;
    transform: rotate(0deg);
  }
  .hc-mode-facet-arrow {
    fill: #fff;
  }
}
