@import "../../../variables";

.serp-container {
  padding-top: 30px;
  max-width: 1230px;
  margin: 0 auto !important;
  min-height: 100vh;
  .serp-container-inner {
    max-width: 1175px;
    margin: 0 auto;

    .serp-container-leftside {
      max-width: 257px;
      margin-bottom: 30px;
    }
    .serp-container-rightside {
      padding: 0;
      padding-left: 5px;
    }
  }
}
@media (max-width: 600px) {
  .serp-container-leftside {
    &.tab-filter-wr {
      margin-bottom: 0 !important;
    }
  }
}
