@import "../../../variables";

.pdp-tabcontent {
    padding-top: 10px;
    .react-horizontal-scrolling-menu--wrapper {
        .react-horizontal-scrolling-menu--inner-wrapper {
            overflow-y: initial;
            display: flex;
            align-items: center;

            .react-horizontal-scrolling-menu--scroll-container {
                align-items: center;
                .react-horizontal-scrolling-menu--item {
                    margin-right: 10px;
                    .tab-item {
                        @include product-info(18px, 600, 28px);
                        color: #666666;
                        text-decoration: none;
                        padding: 9px 15px;
                        @include custom-link;
                        box-shadow: none;
                        border: none;
                        background: none;
                        text-align: center;
                        display: block;
                        width: 132px;
                        @media screen and (max-width: 400px) {
                            padding: 9px 3px;
                            width: 243px;
                        }
                        @media screen and (max-width: 350px) {
                            padding: 9px 6px;
                            width: 180px;
                        }
                        &.active-tab {
                            border-bottom: 3px solid #151515;
                            color: #151515;
                        }
                        &:hover {
                            text-decoration: none;
                            cursor: pointer;
                        }
                        &:focus-visible {
                            outline-offset: -6px !important;
                        }
                    }
                }
            }

            .react-horizontal-scrolling-menu--arrow-right {
                padding-left: 30px;
                button {
                    margin-right: 0 !important;
                }
            }
            .react-horizontal-scrolling-menu--arrow-left,
            .react-horizontal-scrolling-menu--arrow-right {
                button {
                    height: 28px;
                    width: 28px;
                    border-radius: 50%;
                    background-color: $pill-bg;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    padding: 0;
                }
            }
        }
    }
}
.arrow-style {
    cursor: pointer;
    flex-direction: "column";
    justify-content: "center";
    right: "5%";
    display: block;
    user-select: "none";
    margin-right: 30px;
    &.arrow-style-disabled {
        display: none !important;
    }
}
