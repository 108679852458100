@import "../../../variables";

.product-header {
  .collection-product-info {
    .info-text {
      padding-top: 20px;
      padding-bottom: 20px;
      @include product-info(13px, 400, 20px);
      color: $text-color-secondary;
    }
    .book-wr {
      margin-bottom: 45px;
      margin-left: 45px;
      .book-img {
        img {
          height: 150px;
        }
      }
      .brand {
        &.book {
          padding: 5px 10px;
        }
        .physical-product {
          padding-right: 6px;
        }
      }
      .product-isbn {
        padding-top: 5px;
      }
    }
    .text {
      ul {
        padding-left: 40px;
      }
      a {
        color: $text-color-secondary;
        text-decoration: none;
      }
    }
  }

  .product-information {
    @include product-infor;
    .collection-title {
      @include product-info(30px, 700, 38px);
      padding-bottom: 15px;
      color: $text-primary;
    }

    .product-title {
      @include product-title(30px);
      color: $text-color-secondary;
      padding-bottom: 15px;
      word-break: break-word;
    }
    .product-title-collection,
    .product-sub-title {
      padding-bottom: 15px;
      color: $text-primary;
    }
    .product-title-collection {
      @include product-info(24px, 700, 32px);
    }
    .product-sub-title {
      @include product-info(24px, 600, 32px);
    }

    .product-modules {
      padding-bottom: 15px;
    }
    .product-bookEd,
    .product-isbn,
    .product-copyright {
      padding-bottom: 10px;
    }
    .product-authors {
      padding-bottom: 5px;
    }
  }

  .product-isbn {
    @include product-isbn;
  }
}

/*--overlayers --*/

.overlay-badge-pdp {
  border-top-left-radius: 9px;
  position: absolute;
  opacity: 0.95;
  top: 0;
  left: 0;
  height: 60px;
  width: 50px;
  background-color: #e6e6e6;

  &:after,
  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #e6e6e6 transparent transparent transparent;
    position: absolute;
  }
  &:before {
    top: 0px;
    left: 49px;
    border-width: 60px 60px 0 0;
  }
  &:after {
    top: 59px;
    left: 0px;
    border-width: 50px 50px 0 0;
  }

  .overlay-text-pdp {
    @include product-info(20px, 400, 21px);
    position: absolute;
    z-index: 70;
    top: 17px;
    left: 3px;
    text-align: center;
    color: #000;
    white-space: normal;
    transform: rotate(-44deg);
    -webkit-transform: rotate(-44deg);

    /* Safari */
    -webkit-transform: rotate(-44deg);

    /* Firefox */
    -moz-transform: rotate(-44deg);

    /* IE */
    -ms-transform: rotate(-44deg);

    /* Opera */
    -o-transform: rotate(-44deg);

    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

    .sub-text {
      @include product-info(12px, 400, 21px);
      position: absolute;
      left: -1px;
      width: 100%;
    }
    &.class-test {
      width: 106px;
      z-index: 70;
      top: 34px;
      left: -8px;
    }
    &.sample {
      top: 20px;
      left: -2px;
    }
  }
}

@media (max-width: 600px) {
  .product-header {
    .product-information {
      .product-title {
        @include product-info(18px, 700, 28px);
      }
      .product-authors,
      .product-copyright,
      .product-isbn {
        @include product-info(16px, 400, 26px);
      }
    }
    .overlay-badge-pdp {
      height: 25px;
      width: 25px;
      &::before {
        left: 25px;
        border-width: 50px 50px 0 0;
      }
      &::after {
        top: 25px;
        left: 0;
        border-width: 50px 50px 0 0;
      }
      .overlay-text-pdp {
        font-size: 11px;
        line-height: 17px;
        top: 8px;
        left: 4px;
        &.sample {
          top: 11px;
          left: 0;
        }
        &.class-test {
          top: 21px;
          left: -26px;
        }
        .sub-text {
          left: 0px;
          font-size: 7px;
          line-height: 11px;
        }
      }
    }
    .collection-product-info {
      .product-image {
        &.book-img {
          img {
            height: 86px;
            max-width: 66px;
          }
        }
      }
      .product-sub-title,
      .product-title-collection {
        @include product-info(18px, 700, 28px);
      }
    }
  }
}
@media (min-width: 992px) {
  .custom-pdp {
    .product-header {
      min-height: 296px;
    }
  }
}

@media (max-width: 992px) {
  .collection-product-info .book-wr {
    margin-left: 30px !important;
  }
}
