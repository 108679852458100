@import "../node_modules/@pearson-components/elements-sdk";
@import "./variables";

html {
    color: $text-primary;
    font-size: 62.5%;
    /* font-size 1em = 10px on default browser settings */
    line-height: inherit;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
address {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Open Sans", serif;
    font-weight: 300;
    line-height: 1.2;
    color: $text-primary;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "lnum" 1;
    font-feature-settings: "lnum" 1;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    direction: ltr;
    background-color: #fff;
    opacity: 1;
    position: relative;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

*:focus:not(:focus-visible) {
    outline: 0;
}

a {
    text-decoration: none;
}

a:hover,
a:active,
a:focus {
    color: $text-color-secondary;
    text-decoration: none;
}

input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):active,
select:active,
textarea:active,
.input:active,
.form-control:active,
input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):focus,
select:focus,
textarea:focus,
.input:focus,
.form-control:focus,
a {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

// accessibility implementation

[tabindex]:not([tabindex^="-"]),
a {
    &:focus-visible {
        box-shadow: none;
        outline: 2px solid #000 !important;
        outline-offset: 4px;
    }
    &:focus {
        box-shadow: none;
        outline: 0;
    }
}

.sh-footer a:focus {
    outline: 2px solid #ccc !important;
}
input[type="radio"] {
    opacity: 1 !important;
    position: absolute;

    &:focus-visible {
        box-shadow: none;
        outline: 2px solid #000 !important;
        outline-offset: 4px;
    }
    &:focus {
        box-shadow: none;
        outline: 0 !important;
    }
}

// skip link
.fixedHeaderSkipNavLinkOnBlur {
    .pdp-container {
        margin-top: 90px;
    }
}
.fixedHeaderSkipNavLinkOnFocus {
    margin-top: -10px;
    .console-header-fixed {
        top: 33px;
    }
    .hamburger-nav-pdp {
        top: 67px;
    }
    .pdp-search-wr {
        .searcbar-wrap {
            margin-top: 34px;
        }
    }
}
.fixedHeaderSkipNavLinkOnKeyPress {
    .pdp-container {
        padding-top: 135px;
    }
}

.fixed-skip-nav {
    position: relative;
    z-index: 3;
    display: inline-block;
}
.skip-nav {
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 10px 15px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    font-size: 16px;
    font-weight: 400;
    &.skip-nav-fixed-header {
        top: -90px;
        &:focus,
        &:focus-visible {
            top: -90px;
        }
    }
    &:focus {
        color: #006f8f;
        position: static;
        width: auto;
        height: auto;
        margin: 1px;
        clip: auto;
        text-decoration: underline;
    }
    &:focus-visible {
        box-shadow: none;
        outline: 2px solid #000 !important;
        outline-offset: -10px !important;
    }
}

.error-text {
    font-size: 13px !important;
    color: $warning-color !important;
}

/*--button styles---*/
button {
    cursor: pointer;
    background: none;
    border: none;
    &.text-btn-link {
        background: none;
        border: none;
        padding: 0;
        &:hover {
            text-decoration: underline;
        }
    }

    &:active,
    &:focus {
        border-color: transparent;
        outline: 0 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    &:focus-visible {
        outline: 2px auto #000 !important;
        outline-offset: 1px !important;
    }
}

.custom-btn {
    font-size: 16px;
    font-weight: 600;
    display: block;
    border: none;
    text-align: center;
    color: $text-primary;
    border-radius: 4px;
    padding-top: 12px;
    padding-bottom: 12px;
    &.fill-black-btn {
        background-color: #000;
        color: #fff;
    }
    &:active,
    &:focus {
        outline: 0 !important;
        outline-offset: 0 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    &:focus-visible {
        outline: 2px auto #000 !important;
        outline-offset: 4px !important;
    }

    &.primary-btn {
        background-color: $primary-btn-color;
        border: 2px solid $primary-btn-color;

        &:hover {
            color: $text-primary;
            background-color: #fff;
            border: 2px solid $text-primary;
        }
    }

    &.secondary-btn {
        background-color: $text-primary;
        color: #fff;
        border: 2px solid $text-primary;

        &:hover {
            color: $text-primary;
            background-color: #fff;
            border: 2px solid $text-primary;
        }
        &:focus-visible {
            outline: 2px auto #000 !important;
            outline-offset: 4px !important;
            background-color: $text-primary;
            color: #fff;
        }
    }

    &.outline-btn {
        border: 2px solid $text-primary;
        background-color: white;

        &:hover {
            background-color: $text-primary;
            color: #fff;
        }
        &:focus-visible {
            outline: 2px auto #000 !important;
            outline-offset: 4px !important;
            background-color: $text-primary;
            color: #fff;
        }
    }

    &.disabled-btn {
        background-color: #e6e6e6;
        color: #666666;
        pointer-events: none;
        border: 2px solid #e6e6e6;
    }
}

.text-link {
    text-decoration: none;
    color: $link-color;
    font-weight: 400;
    cursor: pointer;
    &.verifyTxtPdp {
        padding-left: 0 !important;
    }
    &:hover {
        text-decoration: underline;
    }
    &:hover,
    &:active,
    &:focus {
        color: $link-color;
        border-color: transparent;
        outline: 0 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    &:focus-visible {
        outline: 2px auto #000 !important;
        outline-offset: 1px !important;
    }
    &.serp{
        &:focus-visible {
            float: right;
            outline-offset: 4px !important;
        }
    }
}

.des-text {
    @include product-info(14px, 400, 21px);
    color: $text-color-secondary;
}

.facet-header {
    button {
        &:focus-visible {
            outline: 2px auto #000 !important;
            outline-offset: 0 !important;
        }
    }
    .facet-items {
        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            text-align: left;
            color: #151515;
        }
    }
}
.offer-content {
    &:not(:first-child) {
        border-top: 1px solid $pill-bg;
        padding-top: 20px;
    }
}
.offer-separator {
    border-top: 1px solid $pill-bg;
    margin-top: 30px;
}
.offer-bottom-separtor {
    padding: 20px 0;
    margin: 0 30px;
    border-bottom: 1px solid $pill-bg;
}

/*--Typehead sub info--*/
.product-authors,
.product-isbn,
.product-bookEd,
.product-isbn,
.product-modules,
.sticky-product-bookEd,
.sticky-product-isbn,
.product-copyright {
    color: $text-sub;
}

/*--product brand---*/
.brand {
    span {
        color: $text-sub;
        @include product-info(14px, 400, 21px);
        sup {
            top: -6px;
            left: -2px;
            color: $text-color-secondary;
            @include product-info(8px, 400, 11px);
        }
    }
    p {
        color: $text-sub;
        @include product-info(14px, 700, 21px);
    }
}

/*--search container--*/
.search-wr {
    position: absolute;
    margin-top: 25px;
    width: 100%;
    display: inline-block;
    .searcbar-wrap {
        margin: 0;
        position: absolute;
    }

    input.searchdbar {
        margin-top: 0;
    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
        width: 60%;
    }

    @media screen and (min-width: 1200px) {
        width: 75%;
    }
}
.header-srp-container {
    .search-wr {
        margin-left: 210px;
        .searcbar-wrap {
            left: -60px;
        }
    }
}

/*--pdp dynamic content--*/

.text {
    color: $text-color-secondary;
    a {
        color: $text-color-secondary;
        text-decoration: none;
        @include custom-link;
    }

    p {
        margin-bottom: 0;
        color: $text-color-secondary;
        font-weight: 400;
    }
}

.pdp-search-wr {
    position: fixed;
    z-index: 3;
    margin-top: 25px;
    .search-dropdown {
        max-height: calc(85vh);
        overflow-y: auto;
    }
}
.pdp-hamburger {
    .hamburger-nav {
        z-index: 3;
        position: fixed;
        width: 48px;
    }
    .hb-nav-content {
        z-index: 3;
        position: fixed;
        top: 0;
        bottom: 0;
        height: auto;
        overflow-y: scroll;
        overflow-x: hidden;
        max-width: 1200px;
    }
}
.horizontal-separator {
    position: fixed;
    display: block;
    top: 90px;
    height: 1px;
    width: 100vw;
    background-color: #ccc;
    padding: 0;
    z-index: 1;
}

.toc {
    li {
        margin-left: 20px;
    }
}

@media (min-width: 1240px) {
    .custom-pdp {
        .in-header-wr {
            margin-left: calc((100vw - 1280px) / 2) !important;
        }
        .console-header-fixed {
            .header-section {
                margin-left: calc((100vw - 1280px) / 2) !important;
            }
        }
    }
}

@media (max-width: 1200px) {
    .header-hm-container {
        max-width: 1110px !important;
    }
    .console-header {
        padding: 0 10px 0 45px !important;
    }
}

// High contrast mode image style modifcation
@media (-ms-high-contrast: active), (forced-colors: active) {
    .hc-mode-search,
    .hc-arrow,
    .hc-tooltip,
    .hc-icons {
        fill: #fff;
    }
    .hc-disabled {
        fill: #e6e6e6;
    }
    .view-text {
        color: black;
    }
    .institute-wrap .selected-institute {
        background: #ffffff url("./components/images/hc-selected-right.svg") no-repeat scroll 18px 8px !important;
    }
    .custom-btn {
        &.fill-black-btn {
            border: 2px solid #fff;
        }
    }
    .institutional-licensing-wr {
        border: 1px solid;
        .intitute-icon {
            border: 1px solid;
        }
        &.access-code-wr {
            border: none;
        }
    }
    .react-horizontal-scrolling-menu--arrow-left,
    .react-horizontal-scrolling-menu--arrow-right {
        button {
            border: 1px solid;
        }
    }
}

@media (max-width: 992px) {
    .search-wr {
        display: none;
    }
    .mobile-search-wr {
        position: absolute;
        display: flex;
        right: 175px !important;
        height: 90px;
        margin-top: 1.5px;

    }
    .pdp-search-wr {
        &.mobile-search-wr {
            right: 230px !important;
        }
    }

    .signed-in-wr {
        &.header-srp-container {
            .mobile-search-wr {
                right: 145px !important;
            }
        }
        .pdp-search-wr {
            &.mobile-search-wr {
                right: 185px !important;
            }
        }
    }
    .hm-discipline-container {
        padding: 0 25px;
        .container {
            max-width: 900px;
            padding: 0;
        }
    }
}

@media (min-width: 992px) {
    .mobile-search-wr {
        display: none;
    }
}

@media (max-width: 600px) {
    .header-srp-container {
        .mobile-search-wr {
            right: 155px !important;
        }
    }
    .pdp-search-wr {
        &.mobile-search-wr {
            right: 200px !important;
        }
    }
    .signed-in-wr {
        &.header-srp-container {
            .mobile-search-wr {
                right: 125px !important;
            }
        }
        .pdp-search-wr {
            &.mobile-search-wr {
                right: 155px !important;
            }
        }
    }
    @media (-ms-high-contrast: active), (forced-colors: active) {
        .institute-wrap .selected-institute {
            background: #ffffff url("./components/images/hc-selected-right.svg") no-repeat scroll 1px 7px !important;
        }
    }
}

@media (max-width: 320px) {
    .hm-container {
        max-width: 320px;
    }
}

@media (min-width: 600px) and (max-width: 992px) {
    .header-srp-container .search-wr .searcbar-wrap {
        left: -30px !important;
    }
}
@media (min-width: 1240px) {
    .pdp-search-wr {
        margin-left: calc(50vw - 485px) !important;
    }
}
@media (min-width: 992px) and (max-width: 1200px) {
    .header-srp-container .search-wr .searcbar-wrap {
        left: -35px;
    }
    .pdp-search-wr .searcbar-wrap {
        margin-left: 200px;
    }
}
@media (min-width: 1200px) and (max-width: 1240px) {
    .console-header-fixed .header-section .pearson-logo {
        margin-left: -12px;
    }
    .pdp-search-wr {
        left: 231px;
    }
}
