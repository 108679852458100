@import "../../../variables";

.separator {
  border-top: 1px solid $input-border-02;
  margin: 29px 0;
}
p {
  margin-bottom: 0;
}
.opt-title {
  @include product-info(18px, 600, 28px);
  color: $text-primary;
}

.access-info {
  font-size: 16px;
  line-height: 26px;
  color: $text-color-secondary;
  font-weight: 400;
  padding-top: 15px;
  &.k12-offer {
    margin-bottom: 30px;
    margin-top: -15px;
    padding-top: 0;
  }
  &.check-later-txt {
    padding: 20px 0;
  }
  &.invite-txt {
    padding-bottom: 15px;
    padding-top: 0;
  }
  &.add-institution-btn {
    font-weight: 400;
    @include custom-link;
    padding-top: 0;

    &:active,
    &:focus {
      color: $text-color-secondary;
    }
  }
}
.access-opt-info {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  .access-opt-info-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    padding-bottom: 5px;
  }

  .currency-txt {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}
.access-opt-info:nth-last-child() {
  margin-bottom: 0;
}
