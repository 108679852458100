@import "../../../variables";

.contact-sales-text {
    border-top: 1px solid $pill-bg;
    padding-top: 20px;
    margin-top: 20px;
}
.pdp-rightside-wr {
    &.pdp-k12-buyer-wr {
        padding-left: 0;
        padding-right: 0;
        .custom-padding {
            padding-left: 30px;
            padding-right: 30px;
            .coming-soon-k12{
                padding-top:30px;
            }
        }

        @media (min-width: 992px) and (max-width: 1132px) {
            width: 97% !important;
        }
        @media (min-width: 1133px) and (max-width: 1199.98px) {
            width: 96% !important;
        }
    }
    &.pdp-rightside-wr.national {
        margin-left: 0;
        border: none;
        box-shadow: none;
        margin-bottom: 0;
        border-top: 1px solid $horizontal-line;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

    }
   
    &.pdp-hied-wr, &.pdp-hied-student-wr{
        padding: 0;
        .pdp-btn-container {
            padding: 30px;
        }
        .hr-line {
            border-top: 1px solid $horizontal-line;
        }
        .pdp-rightside-wr.available-soon {
            margin-left: 0;
            border: none;
            box-shadow: none;
            margin-bottom: 0;
            border-top: 1px solid $horizontal-line;
            border-radius: 0;

            .access-info {
                padding-top: 10px;
            }
        }
        h3.opt-title {
            padding-bottom: 20px;
        }
        .verify-instructor {
            margin: 0 30px 30px;
        }
        .access-container, .pdp-rightside-wr.national {
            padding: 25px 30px 30px;
            .offer-opt-info-title {
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                color: $access-titles;
                padding-top: 20px;
            }
            .offer-info {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: $text-color-secondary;
            }
            .available-offer {
                padding-top: 20px;

                .offer-info, .offer-currency-txt {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 25.6px;
                    color: $alert-color;
                    padding-top: 0;
                    padding-bottom: 5px;
                }
                .offer-currency-txt {
                    text-align: right;
                }
                
            }
            .book-price-vary {
                padding-top: 20px;
            }
            
        }
        @media (min-width: 992px) and (max-width: 1131px) {
            width: 97% !important;
        }
        @media (min-width: 1132px) and (max-width: 1199.98px) {
            width: 96% !important;
        }
    }

}
.seat-license-activated-wr {
    .seat-license-img {
        margin-top: -60px;
        margin-bottom: 15px;
    }
    p {
        color: $text-color-secondary;
        margin-bottom: 15px;
        text-align: center;
        &:nth-child(2) {
            @include product-info(16px, 600, 26px);
            border-bottom: 1px solid $pill-bg;
            padding-bottom: 15px;
            margin-top: 12px;
        }
        &:nth-child(3) {
            @include product-info(16px, 400, 26px);
            
        }
        .offer-bottom-separtor{
            padding: 20px 0;
            margin:0 30px;
            // h3{
                border-bottom:1px solid $pill-bg;
            // }
            
        }
    }
}
