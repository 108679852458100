@import "../../../../../../variables";

.access-code-wr {
    padding: 0;
    background: transparent;
    margin: 20px 30px 0;
    .access-title {
        @include product-info(18px, 600, 28px);
        color: $text-primary;
    }
    .pricing-des {
        color: #444444;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $pill-bg;
    }
}
