@import "../../../variables";

.getting-started-page-wr {
    padding-bottom: 150px;
    &.pdp-wrap {
        margin: 90px auto 0 !important;
        .product-header .product-information .product-title {
            color: $text-primary;
        }
        .product-image img {
            max-width: 162px;
        }
        .product-header {
            min-height: 0;
        }
    }
}
.back-to-detail-link {
    @include product-info(14px, 400, 23px);
    @include custom-link;
    color: $link-color !important;
    text-decoration: none;
    display: block;
    margin: 8px 0 30px;

    svg {
        margin-right: 15px;
    }
}
.join-course-info {
    margin: 45px 0;
    h2,
    h3 {
        color: $text-primary;
        margin-bottom: 0;
        margin-bottom: 10px;
    }
    h2 {
        @include product-info(30px, 300, 38px);
    }
    p {
        @include product-info(16px, 400, 26px);
        color: $text-color-secondary;
        margin-bottom: 0;
    }
    h3 {
        @include product-info(24px, 400, 32px);
    }

    .get-started-input-wr {
        margin: 30px 0;

        .input-container {
            .input-wr {
                box-sizing: border-box;
                .custom-form-control {
                    min-height: 48px;
                    border-radius: 4px;
                    border: 1px solid #919191;
                    color: $text-color-secondary;
                    font-size: 16px;
                    padding: 0 13px;
                }
                .input-alert-container {
                    border: 1px solid $warning-color;
                }
            }
            #join-btn {
                height: 48px;
            }

            button {
                @include product-info(16px, 600, 22px);
                margin-left: 15px;
                min-height: 44px;
                min-width: 231px;
                &.custom-btn {
                    padding: 0;
                }
                &.btn {
                    &:disabled {
                        opacity: 1;
                        color: #666;
                        background-color: $pill-bg;
                        border: none;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .getting-started-page-wr {
        padding-left: 50px;
    }
}

@media (max-width: 992px) {
    .join-course-info {
        .input-wr {
            width: 70%;
        }
    }
}

@media (max-width: 600px) {
    .get-started-input-wr {
        .input-container {
            flex-direction: column !important;
            .input-wr {
                width: 100%;

                .custom-form-control {
                    box-sizing: border-box;
                }
            }

            button {
                margin-left: 0 !important;
                margin-top: 15px;
            }
        }
        .join-course-info h3 {
            padding-top: 15px;
        }
    }
}

@media (min-width: 992px) {
    .getting-started-page-wr.pdp-wrap .product-header {
        min-width: 770px;
        min-height: 0;
    }
    .join-course-info {
        .get-started-input-wr {
            .input-container {
                flex-direction: row;
                align-items: flex-start;
                .input-wr {
                    .custom-form-control {
                        min-width: 524px;
                    }
                }
            }
        }
    }
}
