@import "../../../variables";

.relProduct-container {
    &:not(:last-child) {
        margin-bottom: 30px;
    }

    .relProduct-image {
        img {
            height: 160px;
            width: 121px;
            border-radius: 10px;
            object-fit: cover;
        }
    }

    .relProduct-info {
        padding-left: 20px;

        .relProduct-title {
            @include product-info(21px, 400, 30px);
            padding-bottom: 10px;
            color: $text-primary;
        }

        .relProduct-name {
            @include product-info(14px, 600, 20px);
            padding-bottom: 10px;
            word-break: break-word;
            color: $text-color-secondary;

            a {
                @include custom-link;
                text-decoration: none;
                color: $link-color;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .relProduct-isbn {
            color: $text-sub;
            @include product-info(14px, 400, 21px);
            padding-bottom: 5px;
            padding-right: 20px;

            span {
                font-weight: 700;
            }
        }
    }
}

@media (max-width: 600px) {
    .relProduct-container {
        .relProduct-image {
            img {
                width: 66px;
                height: 90px;
            }
            .relProduct-isbn {
                word-break: break-word;
            }
        }
        .relProduct-info{
            min-width: 90px;
        }
    }
}
