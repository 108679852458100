@import "../../../variables";

.subject-category-wr{
  margin-bottom: 20px
}

.filter-displines {
    @include product-info(16px, 400, 26px);
    padding: 0;
    word-break: break-word;
    color: $text-primary;
    .sub-category {
      font-weight: 700;
      padding: 20px 0 0 0;
    }
    .subject-discipline{
      @include custom-link;
      color: $text-primary;
      strong{
        font-weight: 600;
      }
    }
    .sub-item{
      ul{
        li{
          padding-top: 10px;
          list-style: none;
        }
      }
    }
    .sub-item-discipline {
      padding: 10px 0 0;
      color: $text-primary;
      text-decoration: none;
    }
  }
 
  .show-link {
    margin: 0 0 15px;
    @include product-info(16px, 400, 26px);
    color: $link-color;
  }