@import "../../../variables";

.searcbar-wrap {
  position: relative;
  max-width: calc(570px);
  width: 100%;
  margin: 45px auto 0;
  input.searchdbar {
    max-width: 528px;
    width: 100%;
    float: none;
    display: block;
    border-radius: 50px;
    padding: 10px 15px 10px 40px;
    height: 17px;
    color: #444;
    @include product-info(14px, 400, 18px);
    border: 1px solid #444;
    &.searchdbar-active {
      border-radius: 20px 20px 0 0;
    }
  }

  .searchbar-icon {
    position: absolute;
    top: 19px;
    left: 15px;
    transform: translate(0, -50%);
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;

    &:focus-visible {
      outline-offset: 6px !important   ;
    }
  }
  .searchbar-close-icon {
    display: none;
    position: absolute;
    margin: 0;
    padding: 0;
    border: 0;
    right: 16px;
    border-radius: 50%;
    top: 24px;
    transform: translateY(-16px);
    background-color: transparent;
    &.searchbar-close-active {
      display: block;
    }
  }
}

input:focus {
  outline: none;
}

.search-dropdown {
  border: 1px solid #444;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 7px 4px 2px rgba(0, 0, 0, 0.25);
  border-top: none;
  z-index: 1;
  position: absolute;
  width: 99.7%;
  background: #fff;
  margin: -2px 0 0 0;
  font-size: 14px !important;
  font-weight: 400;
  .list-group-search-result {
    padding: 10px 38px 0;

    .search-product-list {
      &:nth-child(1) {
        border-top: 1px solid #d8d8d8 !important;
        padding-top: 10px;
      }
      a {
        text-decoration: none;
        @include custom-link;
        &:hover {
          text-decoration-color: $text-primary;
        }
      }
    }
  }

  ul {
    &.list-active {
      border-top: 1px solid #d8d8d8;
      padding-top: 10px;
    }
    li {
      list-style: none;
      .s-items {
        padding: 0 0 5px 0;
      }
      .search-product-items {
        display: inline-flex;
        padding: 10px 0;
        img {
          width: auto;
          height: 80px;
          border-radius: 4px;
          padding-left: 0;
          @include box-shadow;
        }
        .search-iteams {
          padding-left: 10px;
        }
      }
      .search-title,
      .search-product-authors {
        color: #151515;
      }
      .search-author,
      .search-isbn {
        color: #505759;
      }
      .search-product-brand {
        font-size: 10px;
        font-weight: 600;
        color: #151515;
      }
    }
    .search-product-authors {
      padding: 10px 0;
      color: $text-primary;
      text-decoration: none;
      a {
        color: $text-primary;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }

      }
    }

    li {
      list-style: none;
      .s-items {
        padding: 0 0 5px 0;
      }
    }
    .search-results {
      padding: 10px 0 20px;
      .search-results-inner {
        padding-top: 20px;
        border-top: 1px solid #d8d8d8;
      }
      .search-results-left {
        color: $text-primary;
        word-break: break-word;
        strong {
          font-weight: 700;
        }
      }

      .search-results-left,
      .list-group-search-result {
        &:focus-visible {
          outline: 2px auto #000 !important;
          outline-offset: 1px !important;
        }
      }
      .search-results-right {
        text-align: right;
        float: right;
        a {
          @include custom-link;
          color: $text-primary;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.search-dropdown-items {
  &:focus {
    outline: 0 !important;
  }
  a {
    &:focus {
      outline: 2px solid black !important;
    }
  }
}

.list-active {
  border-top: 1px solid #d8d8d8 !important;
  padding-top: 10px !important;
}
@media (max-width: 600px) {
  .hm-header-inner {
    input.searchdbar {
      max-width: 308px;
    }
  }
}
