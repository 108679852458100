@import "../../../variables";

#pdp-menu {
  margin-top: 45px;

  .pdp-tabcontent {
    @include product-info(16px, 400, 26px);
    width: 100%;
    margin-bottom: 45px;
    transition: width 2s;

    .anchor_offset {
      transition: width 2s linear 1s;
      color: $text-color-secondary;

      &:focus-visible {
        box-shadow: none;
        outline: 2px solid #000 !important;
        outline-offset: 4px;
      }

      .pdp-tabcontent-sub-title {
        @include product-info(30px, 300, 38px);
        margin: 30px 0 10px 0;
        color: $text-primary;
      }

      &.selected-content {
        margin-top: 250px;
      }

      &.modules {
        font-weight: 700;

        .list-module {
          margin-left: 10px;
          line-height: 26px;

          &::marker {
            margin-right: 10px;
          }
        }
      }
    }

    .getting-started {
      p {
        color: $text-color-secondary;
      }

      h4 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 0;
        color: $text-primary;
      }

      p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0;

        &:not(:last-child) {
          padding-bottom: 30px;
        }
      }

      br {
        display: none;
      }
      .gs-title {
        font-weight: 700;
        margin-bottom: 6px;
        @include product-info(18px, 600, 28px);
        color: $text-primary;
      }
    }

    .get-details {
      h6,
      .isbn-txt,
      .detail-content,
      p {
        color: $text-color-secondary;
      }

      h6 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-top: 10px;
        margin-bottom: 6px;
      }

      .isbn-txt {
        font-weight: 700;
        font-size: 16px;
      }

      .detail-content {
        margin-bottom: 30px;
      }

      p {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .video-container {
      padding-top: 30px;

      .prototype-video {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        flex: 3;

        embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .features {
      ul {
        margin-bottom: 10px;
        padding-left: 40px;
      }

      .feature-subcontent {
        margin-bottom: 30px;
      }
    }

    .author {
      p {
        font-size: 16px;
        line-height: 26px;
        color: $text-color-secondary;
      }
    }

    // pdp tab navigation collection
    .nav-tabs {
      border-bottom: 1px solid $text-primary;
      flex-direction: row;
      overflow-x: auto;
      flex-wrap: nowrap;

      .nav-link {
        @include product-info(18px, 600, 28px);
        color: #666666;
        text-decoration: none;
        margin-bottom: 0;
        padding: 9px 15px;
        @include custom-link;
        box-shadow: none;
        border: transparent;

        &:hover {
          text-decoration: none;
          border: none;
          transition: none;
          margin-bottom: 0;
          padding: 9px 15px;
        }

        &.active {
          color: $text-primary;
          border-bottom: 3px solid $text-primary !important;
          background: transparent;
        }
        
        &:focus-visible {
          outline-offset: -6px !important   ;

          @media (max-width: 991px) {
            outline-offset: -6px !important   ;
          }
      }
    }
  }
}

.defaultKey {
  .anchor_offset {
    &:before {
      min-height: 0;
      margin-top: 0;
    }
  }
}

@media (max-width: 600px) {
  #pdp-menu {
    margin-top: 30px;
    .pdp-tabcontent {
      margin-bottom: 30px;
      padding-top: 0;
      .video-container {
        padding-top: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .anchor_offset {
    &:before {
      display: block;
      content: " ";
      min-height: 300px;
      margin-top: -300px;
      width: 100%;
      visibility: hidden;
      transition: width 2s linear 1s;
    }
  }
  .pdp-tabcontent {
    max-width: 780px;
  }
}
}