@import "../../../variables";

.feature-main-wr {
    padding: 10px 60px 0;
}
.feature-content-container {
    border-bottom: 1px solid $input-border-01;
    padding-bottom: 30px;
    display: -webkit-flex; /* Safari */
    display: flex;
    width: 100%;
    .feature-btn-container {
        box-sizing: border-box;
        margin-top: 10px;
        .feature-btn {
            width: 100%;
            max-width: 100%;
        }
    }
    .feature-info {
        width: 100%;
    }

    &:nth-of-type(odd) {
        .feature-info {
            padding-left: 30px;
            order: 2;
        }
        .feature-btn-container {
            padding-left: 83px;
        }

        .features-img {
            order: 1;
            img {
                float: left;
            }
        }
    }
    &:nth-of-type(even) {
        .feature-info {
            padding-right: 30px;
            order: 1;
        }
        .feature-btn-container {
            padding-right: 83px;
        }

        .features-img {
            order: 2;
            img {
                float: right;
            }
        }
    }

    &:not(:first-child) {
        padding-top: 30px;
    }
    &:last-child {
        border-bottom: none !important;
        padding-bottom: 0 !important;
    }
    .feature-inner-container {
        width: 100%;
        .features-img {
            width: 200px;
            height: 200px;
            &:before {
                display: table;
                content: " ";
            }

            img {
                height: 100%;
                width: 200px;
                object-fit: cover;
            }
        }
        .feature-info {
            &:before {
                display: table;
                content: " ";
            }
            .feature-primary-title,
            .feature-secondary-title {
                color: $text-primary;
                margin: 0;
                word-break: break-word;
            }
            .feature-secondary-title {
                @include product-info(24px, 400, 32px);
                padding-bottom: 10px;
            }
            .feature-primary-title {
                @include product-info(18px, 600, 28px);
            }
            .feature-description {
                @include product-info(16px, 400, 26px);
                color: $text-color-secondary;
                word-break: break-word;
            }
        }
    }
    .feature-clearfix {
        clear: both;
    }
}

@media (min-width: 600px) and (max-width: 992px) {
    .feature-content-container:nth-of-type(odd) .feature-btn-container {
        padding-left: 111px;
    }
    .feature-content-container:nth-of-type(even) .feature-btn-container {
        padding-right: 111px;
    }
    .feature-main-wr {
        padding: 0 30px;
    }
}
@media (max-width: 600px) {
    .feature-main-wr {
        padding: 0 15px;
    }
    .feature-content-container {
        &:nth-of-type(odd) {
            .feature-btn-container {
                padding-left: 0;
            }
        }
        &:nth-of-type(even) {
            .feature-btn-container {
                padding-right: 0;
            }
        }
        .features-img {
            height: 90px !important;
            img {
                width: 90px !important;
            }
        }
        .feature-info {
            .feature-secondary-title {
                font-size: 18px !important;
                font-weight: 600 !important;
                line-height: 28px !important;
            }
        }
    }
}
