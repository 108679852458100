@import "../../../variables";

.pagination {
  margin: 15px 0 30px;
  .pagination-item {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    &.active {
      background-color: #ae367e;
      border-radius: 50%;
      border: 1px solid #fff;
      a {
        color: #fff !important;
      }
    }
    &:not(:first-child),
    &:not(:last-child) {
      a {
        display: block;
        @include product-info(16px, 400, 26px);
        color: #333;
        padding: 8px 16px;
        text-decoration: none;
        @include custom-link;
      }
    }
    &:last-child {
      &.disabled {
        a {
          background: url(../../images/pagination-right-disabled.svg) no-repeat scroll center center;
        }
      }
    }
    &:first-child {
      &.disabled {
        a {
          background: url(../../images/pagination-left-disabled.svg) no-repeat scroll center center;
        }
      }
    }
    &.disabled,
    &:last-child,
    &:first-child {
      margin: 0;
      display: block;
      width: 42px;
      height: 42px;
      a {
        font-size: 0;
        display: block;
        margin: 0;
      }
    }
    &:last-child {
      a {
        background: url(../../images/pagination-right-arrow.svg) no-repeat scroll center center;
      }
    }
    &:first-child {
      a {
        background: url(../../images/pagination-left-arrow.svg) no-repeat scroll center center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .pagination-wr {
    margin-right: 0 !important;
  }
  .pagination {
    align-items: center !important;
    margin: 30px 0;
    .pagination-item {
      display: none;
      &:last-child {
        order: 3;
      }
      &:first-child {
        order: 1;
      }
    }
    &:after {
      content: attr(data-current-page);
      order: 2;
      margin: 0 12px;
      display: inline-block;
      @include product-info(16px, 400, 26px);
    }
  }
  .pagination-wr {
    justify-content: center !important;
  }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
  .pagination .pagination-item:first-child.disabled a,
  .pagination .pagination-item:last-child.disabled a,
  .pagination .pagination-item:last-child a,
  .pagination .pagination-item:first-child a {
    background: url(../../images/hc-pagination-arrow.svg) no-repeat scroll center center !important;
  }
  .pagination .pagination-item:first-child.disabled a,
  .pagination .pagination-item:first-child a {
    transform: rotate(180deg) !important;
  }
  .pagination .pagination-item:last-child a,
  .pagination .pagination-item:last-child.disabled a {
    transform: rotate(0deg) !important;
  }
}

@media screen and (max-width: 992px) {
  .pagination-item {
    &:last-child {
      &.disabled {
        a {
          background: url(../../images/pagination-right-disabled.png) no-repeat scroll center center !important;
        }
      }
    }
    &:first-child {
      &.disabled {
        a {
          background: url(../../images/pagination-left-disabled.png) no-repeat scroll center center !important;
        }
      }
    }
    &:last-child {
      a {
        background: url(../../images/pagination-right-arrow.png) no-repeat scroll center center !important;
      }
    }
    &:first-child {
      a {
        background: url(../../images/pagination-left-arrow.png) no-repeat scroll center center !important;
      }
    }
  }
}