@import "../../../variables";

.dda-access-info {
  .separator {
    border-top: 1px solid $input-border-02;
    margin: 29px 0;
  }
  .opt-title-dda {
    @include product-info(18px, 600, 28px);
    color: $text-primary;
  }
  .offer-info-dda {
    color: $text-color-secondary;
    @include product-info(16px,400,26px);
    a{
      font-weight: 400;
    }
  }
  .dda-access {
    margin-bottom: 15px;
    margin-top: 15px;
    .radio-btn-access {
      margin-bottom: 10px;
      #option-1,
      #option-2 {
        height: 1px;
        width: 15px;
        display: none;
      }
      input[type="radio"] {
        float: left;
        opacity: 1 !important;
        position: absolute;
        &:focus-visible {
          outline: 2px auto #000 !important;
          outline-offset: 15px !important;
        }
        &:checked + label {
          &:before {
            background-image: url("../../images/radio-bg.png");
            background-size: 10px 10px;
            border-color: #3497ff;
          }
        }
      }
      input[type="radio"] + label {
        &::before {
          background: rgba(0, 0, 0, 0) none no-repeat scroll center center;
          border: 2px solid #ccc;
          border-radius: 25px;
          content: "";
          cursor: pointer;
          display: block;
          height: 16px;
          width: 16px;
          left: 0;
          position: absolute;
          top: 2px;
        }
      }
      .radio-btn-label {
        @include product-info(16px, 400, 26px);
        padding-left: 33px;
        margin-bottom: 0;
        color: $text-primary;
        position: relative;
      }
    }
  }
  .institute-txt {
    margin-bottom: 15px;
  }

  .tooltip {
    display: inline-block;
    background-color: white;
    font-weight: 400;
    color: $text-color-secondary;
    font-size: 13px;
    opacity: 0;

    &.show {
      opacity: 1 !important;
    }
  }
}

input[type="radio"] {
  opacity: 1;
  position: absolute;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-width: 0;
  border-bottom-color: transparent;
  display: none;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0 !important;
}

.tooltip-inner-style {
  margin-left: 29px;
  margin-bottom: 5px;
  max-width: 200px;
  padding: 10px;
  text-align: left !important;
  color: $text-color-secondary !important;
  font-size: 13px;
  text-align: center;
  background-color: white;
  border-radius: 4px;
  border: 1px solid $input-border-01;
  position: relative;
}
.custom-tooltip-arrow {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  -moz-transform: rotate(402deg);
  -webkit-transform: rotate(402deg);
}
#seamless-billing {
  opacity: 1;
  background: none;
  .tooltip-inner {
    @extend .tooltip-inner-style;

    &::after {
      @extend .custom-tooltip-arrow;
      top: 53px;
      left: 12px;
    }
  }
}

#access-card {
  opacity: 1;
  background: none;
  .tooltip-inner {
    @extend .tooltip-inner-style;

    &::after {
      @extend .custom-tooltip-arrow;
      top: 91px;
      left: 12px;
    }
  }
}
