@import "../../../variables";

.mobile-component {
    .mobile-title-section {
        margin-bottom: 30px;
        h3 {
            @include product-info(30px, 300, 38px);
            color: $text-primary;
            margin-bottom: 0;
        }
        .mobile-component-img {
            height: 80px;
            width: 80px;
            object-fit: cover;
        }
    }
    .mobile-content-section {
        h4 {
            @include product-info(18px, 600, 28px);
            color: $text-primary;
        }
        p {
            color: $text-color-secondary;
            @include product-info(16px, 400, 26px);
            padding-top: 15px;
        }
    }

    .app-brand-icon {
        margin-top: 15px;
        flex-direction: column;
        a {
            margin: 10px;
            width: fit-content;
            &:active,
            &:focus {
                outline: 0 !important;
                outline-offset: 0 !important;
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
            }
            &:focus-visible {
                outline: 2px auto #000 !important;
                outline-offset: 4px !important;
            }
        }
        .app-store-icon,
        .google-play-icon {
            height: 40px;
            width: auto;
            object-fit: cover;
        }
    }
    .share-btn {
        width: 100%;
        box-sizing: border-box;
        margin-top: 20px;
    }
}

@media (min-width: 600px) and (max-width: 992px) {
    .app-brand-icon {
        flex-direction: row !important;
    }
}
