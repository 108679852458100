@import "../../../../../../variables";

.institutional-licensing-wr {
    background-color: rgba(0, 127, 163, 0.05);
    position: relative;
    padding: 30px 20px;
    margin: 32px 30px 20px;
    .intitute-icon {
        position: absolute;
        left: 15px;
        top: -15px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: #007fa3;
    }
    .title {
        @include product-info(18px, 600, 28px);
        color: $alert-color;
    }
    .sub-text {
        @include product-info(14px, 400, 21px);
        color: $text-color-secondary;
        margin-top: 5px;
    }
    .available-offer {
        margin-top: 20px;
        .title {
            @include product-info(16px, 600, 26px);
            color: $alert-color;
        }
    }
}
