@import "../../../variables";

/*---Home page - discioline---*/

.container {
  .hm-title {
    @include product-info(24px, 700, 32px);
    text-align: center;
    color: $text-primary;
    margin-top: 45px;
    margin-bottom: 40px;
  }

  .ex-row {
    max-width: 350px;
    padding: 0 0 30px 0;
    margin: 0 22px 0 22px;
    .ex-box-content {
      padding: 20px;

      .discipline-title {
        @include product-info(20px, 600, 32px);

        a {
          color: $text-primary;
          text-decoration: none;
          @include custom-link;
          padding-right: 27px;
        }
      }

      .list-group {
        .list-group-item {
          a {
            color: $text-color-secondary;
            @include custom-link;
          }
        }
      }
    }

    .ex-box-img {
      picture {
        display: block;
        max-width: 350px;
        object-fit: cover;
        overflow: hidden;
        border-radius: 10px 10px 0 0;

        &:hover {
          img {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
        }
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0;
        transition: 0.2s;
      }
    }

    ul {
      padding-top: 15px;
      list-style: none;

      li {
        margin: 0 0 0 20px;
        padding: 0;
        border: none;
        border-radius: 0;
        line-height: 26px;

        a {
          color: $text-color-secondary;
          @include product-info(16px, 400, 26px);
          cursor: pointer;
          text-decoration: none;
        }

        &::before {
          content: "•";
          color: $text-color-secondary;
          display: inline-block;
          margin-left: -14px;
          float: left;
          font-size: 24px;
        }
      }
    }

    h2 a {
      @include product-info(20px, 600, 32px);
      padding: 0 20px 0 0;
      // overriding
      color: #000;
      text-decoration: none;
    }
  }
}

@media (max-width: 992px) {
  .discipline-title {
    a {
      @include product-info(18px, 600, 28px);
    }
  }
  .ex-box-img {
    picture {
      max-width: 225px;
    }
  }
}

@media (max-width: 767.98px) {
  .ex-box-img {
    picture {
      display: block;
      max-width: 350px;
    }
  }
}

@media (max-width: 600px) {
  .ex-box-img {
    picture {
      max-width: 335px;
    }
  }
  .container {
    .hm-title {
      @include product-info(30px, 700, 38px);
      margin-top: 30px !important;
      margin-bottom: 25px !important;
    }
  }
}
