@import "../../../variables";

.alert-wr {
    background-color: $notification-bg;
    width: 100%;
    margin-bottom: 10px;
    .alert {
        max-width: 1120px;
        border-radius: 0 !important;
        font-size: 14px;
        line-height: 21px;
        display: block;
        margin: 0 auto !important;
        padding: 13px 27px 13px 15px !important;
        border: none;

        &.custom-alert {
            .alert-icon {
                background: url("../../images/alert-icon.svg") 0 0 no-repeat;
                height: 21px;
                width: 18px;
                margin-right: 10px;
                &.verify-instructor-icon {
                    margin-right: 0;
                }
                &.warning-icon {
                    background: url("../../images/errorr_icon.svg") 0 0 no-repeat !important;
                }
            }
            .close {
                color: $text-primary;
                height: 20px;
                width: 11px;
                font-size: 21px;
                text-shadow: none;
                opacity: 1;
                line-height: 21px;
                margin: 14px;
                padding: 0;
                z-index: 0;
            }
            a {
                font-weight: 400;
            }
        }

        .notification-txt {
            @include product-info(14px, 400, 21px);
            color: $text-color-secondary;
            a {
                color: $link-color;
                font-weight: 400;
                text-decoration: none;
                @include custom-link;
            }
            .verify-link {
                margin-left: 10px;
            }
            button {
                font-weight: 400;
            }
        }
    }
}

.refresh-modal-dialog {
    .modal-content {
        border-radius: 8px;
        border: 1px solid #fff;
    }
}
.noDisplay-PDP {
    padding-top: 40px;
    height: 100vh;
    margin-bottom: 120px !important;
    box-sizing: border-box;
    h1,
    h2 {
        @include product-info(40px, 300, 48px);
        color: $text-color-secondary;
    }
    p {
        @include product-info(16px, 400, 26px);
        margin: 10px 0 50px;
        color: $text-color-secondary;
    }
    button {
        max-width: 227px;
    }
    .btn-404 {
        margin-bottom: 30px;
    }
    &.error-404 {
        p {
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 574px) {
    .alert-wr {
        .alert {
            &.custom-alert {
                .alert-icon {
                    padding-right: 10px;
                }
            }
            .notification-txt {
                .verify-link {
                    margin-left: 0 !important;
                }
            }
        }
    }
    .verify-account {
        button {
            padding-left: 0 !important;
        }
    }
}

@media (-ms-high-contrast: active), (forced-colors: active) {
    .alert-wr {
        border: 1px solid $notification-bg;
    }
}
