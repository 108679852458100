.product-image {
    position: relative;
    &.sticky-product-img {
        img {
            height: 106px;
        }
        &.pdp-learning-apps {
            img {
                width: 106px;
            }
        }
    }
    img {
        height: 212px;
        width: auto;
        border-radius: 10px;
        box-shadow: 0 1.6px 8.3px 0 rgba(28, 34, 46, 0.2);
    }
}
.pdp-learning-apps {
    img {
        width: 168px;
        height: 168px;
    }
}

@media (max-width: 600px) {
    .product-image img {
        height: 117px;
        width: 90px;
    }
}
