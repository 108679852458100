@import "../../../variables";

.text {
  display: inline;
  width: 100%;
  font-size: 16px !important;
  line-height: 26px;

  p {
    font-size: 16px;
    line-height: 26px;
  }
  .c-show-more {
    display: block;
    position: relative;
    margin: 30px 0;
    text-align: center;
    &.collapsed{
      margin: 0;
      &::after{
        background: linear-gradient(to bottom,rgba(255,255,255,0) 0,#fbfbfb 70%,#fafafa 100%);
      }
    }
    &::after{
      content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    height: 33px;
    background: 0 0;
    }
    .read-or-hide {
      color: $text-primary !important;
      @include product-info(16px, 400, 24px);
      text-align: center;
      padding: 0;
      margin: 20px auto 0;
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 0;
      margin: 0;
      border: 0;
      background-color: transparent;
      color: #151515;
      overflow: hidden;
      &::before ,  &::after{
        background-color: #ccc;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }
      &::before{
        right: 30px;
        margin-left: -50%;
      }
      &::after{
        left: 30px;
        margin-right: -50%;
      }
      svg{
        margin-left: 10px;
      }
      &:focus-visible {
        outline-offset: -1px !important   ;
      }
    }
  }
}



.show-more-fade {
  background: -webkit-linear-gradient(rgba(250, 250, 250, 0) , rgba(250, 250, 250, 1) );
  background: -ms-linear-gradient(rgba(250, 250, 250, 0) , rgba(250, 250, 250, 1) );
  background: -o-linear-gradient(rgba(250, 250, 250, 0) , rgba(250, 250, 250, 1) );
  background: -moz-linear-gradient(rgba(250, 250, 250, 0) , rgba(250, 250, 250, 1) );
  background: linear-gradient(rgba(250, 250, 250, 0) , rgba(250, 250, 250, 1) );
  height: 120px;
  margin-top: -120px;
  position: relative;
  opacity: 1 !important;

}
.table-content-wr {
  border: none;

  .content-container {
    background-color: $main-bg;
    border          : none;

    &:not(:last-child) {
      border-bottom: 1px solid #dbdbdb !important;
    }

    &:active,
    &:focus {
      outline: 0 !important;
    }

    &:hover {
      color          : inherit;
      text-decoration: none;
    }

    .content-title {
      background-color: $main-bg;
      border-bottom   : none;
      padding         : 0;
      color           : $text-color-secondary;

      &:focus {
        outline   : 0 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
      }

      &:hover {
        color          : inherit;
        text-decoration: none;
      }
    }

    .expand-btn {
      position   : relative;
      padding    : 18px 37px;
      background : 0 0;
      border     : 0;
      text-align : left;
      @include product-info(16px,600,20px);
      color      : #333;

      &:before {
        content        : "";
        width          : 13px;
        height         : 12px;
        position       : absolute;
        top            : 25px;
        left           : 5px;
        background-size: cover;
        background     : url(../../images/accordion-expand-btn.svg);
      }

      &:hover {
        color          : inherit;
        text-decoration: none;
      }

      &:active,
      &:focus {
        outline        : 0 !important;
        box-shadow     : 0 0 0 0 rgba(0, 0, 0, 0) !important;
        border         : none;
        text-decoration: none;
      }
    }

    .collapse-btn {
      &:before {
        content        : "";
        width          : 13px;
        height         : 3px;
        position       : absolute;
        top            : 30px;
        left           : 5px;
        background-size: cover;
        background     : url(../../images/accordion-collapse-btn.svg);
      }
    }

    .accordion-panel {
      padding: 0 37px;
      color  : $text-color-secondary;

      li {
        list-style   : none;
        margin-bottom: 18px;
        color        : $text-color-secondary;
      }

      ol {
        list-style: none;

        &:nth-child(1) {
          margin-bottom: 18px;
        }

        li {
          margin: 0 0 18px;
          color : $text-color-secondary;
          @include product-info(14px,400,20px);

          strong {
            padding-right: 10px;
          }

          ol {
            margin-top : 18px;
            margin-left: 35px;
            color      : $text-color-secondary;
          }
        }
      }
    }
  }
}

@media (-ms-high-contrast: active), (forced-colors: active)  {
  .table-content-wr .content-container .expand-btn:before{
    background: url(../../images/hc-accordion-expand.svg) !important;
  }
  .table-content-wr .content-container .collapse-btn:before {
    background: url(../../images/hc-accordion-collapse.svg) !important;
  }
}