@import "../../../variables";

.hm-header {
  background: linear-gradient(180deg, #505759 0%, $text-color-secondary 100%);
  background: -webkit-linear-gradient(180deg, #505759 0%, $text-color-secondary 100%);
  background: -ms-linear-gradient(180deg, #505759 0%, $text-color-secondary 100%);
  background: -o-linear-gradient(180deg, #505759 0%, $text-color-secondary 100%);
  background: -moz-linear-gradient(180deg, #505759 0%, $text-color-secondary 100%);
  background-color: $text-color-secondary;
  padding: 45px 20px;
  .hm-header-inner {
    h1 {
      color: #fff;
      text-align: center;
      @include product-info(50px, 300, 60px);
    }
  }
}
.hm-container {
  .alert-wr {
    margin-bottom: 0;
  }
}
@media (max-width: 600px) {
  .hm-header-inner {
    h1 {
      @include product-info(42x, 300, 52px);
    }
  }
}
@media (max-width: 600px) {
  .hm-header-inner {
    h1 {
      @include product-info(38px, 300, 46px);
    }
    .searcbar-wrap {
      max-width: 335px;
    }
    input.searchdbar {
      max-width: 308px;
    }
  }
  .hm-discipline-container {
    padding: 0 20px;
    .container {
      max-width: 350px !important;
    }
  }
}
